export enum SNACKBAR_TYPES {
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
	SUCCESS = 'success',
}

export enum SNACKBAR_BUTTON_TYPES {
	CLOSE = 'CLOSE',
	RETRY = 'RETRY',
	FIX = 'FIX',
	DISMISS = 'DISMISS',
	UNDO = 'UNDO',
	RESEND = 'RESEND',
}

export enum ERROR_MESSAGES {
	LOGIN = 'Failed to Login. Please check your details and try again.',
	SIGNUP = 'Failed to register your account. Please check your details and try again.',
	MESSAGE = 'Failed to send a message. Please check your network and try again.',
	DISMISS = 'Failed to dismiss this support ticket. Please try again later.',
	DELETE_POST = 'Failed to delete this post. Please try again later.',
	KEEP_POST = 'Failed to keep this post. Please try again later.',
	CONTACT = 'Failed to send a message. You cannot contact a banned user.',
	EXPORT = 'Failed to export the user list. Please try again or choose a different set of dates.',
}

export enum MODAL_TYPES {
	DISMISS_SUPPORT = 'DISMISS_SUPPORT',
	SEND_SUPPORT_MESSAGE = 'SEND_SUPPORT_MESSAGE',
	DELETE_REPORT = 'DELETE_REPORT',
	KEEP_REPORT = 'KEEP_REPORT',
	CONTACT_USER = 'CONTACT_USER',
	USER_CONTACT_CONFIRMED = 'USER_CONTACT_CONFIRMED',
	BAN_USER = 'BAN_USER',
	USER_MESSAGE = 'USER_MESSAGE',
	USER_QUESTION = 'USER_QUESTION',
	USER_SUGGESTION = 'USER_SUGGESTION',
	USER_DELETE_POST = 'USER_DELETE_POST',
	USER_SUPPORT_QUESTION = 'USER_SUPPORT_QUESTION',
	USER_REPORTED_POST = 'USER_REPORTED_POST',
}

export enum SUCCESS_MESSAGES {
	CREATE_CARD = '',
}

export enum SEARCH_PILLS {
	ALL = 'All',
	BANNED = 'Banned',
}

export enum OVERVIEW_PILLS {
	MESSAGES = 'Messages',
	QUESTIONS = 'Questions',
	SUGGESTIONS = 'Suggestions',
	SUPPORT_QUESTIONS = 'Support Questions',
	REPORTED_POSTS = 'Reported Posts',
	USER_DETAILS = 'User Details',
}
