import { useState } from 'react'
import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useGetUserRequestsQuery } from '../../../services/UserService'
import UserRequestSkeleton from '../../molecules/Skeletons/UserRequestSkeleton'
import SuggesterfyCardError from '../../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../../atoms/SuggesterfyCardLoading'
import UserSupportCard from '../../molecules/UserSupportCard'
import { ReturnSupport } from '../../../types/serviceTypes'
import { MODAL_TYPES } from '../../../assets/data/enum'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { openModal } from '../../../features/modal/modalSlice'

const SupportQuestions:RFC<IdProps> = ({ id }) => {
  const { isLoading, isError, data } = useGetUserRequestsQuery({ take:100, skip:0, userId:id}) 
  const [ selectedRequest, setSelectedRequest ] = useState<ReturnSupport>()
  const dispatch = useSuggesterfyDispatch()

  const handleClickCard = (request:ReturnSupport) => {
    const modalState = {
      modalType: MODAL_TYPES.USER_SUPPORT_QUESTION,
      request
    }
    setSelectedRequest(request)
    dispatch(openModal(modalState))
  }

  const renderSupportRequests = () => {
      if (!data)     return <UserRequestSkeleton />
      if (isError)   return <SuggesterfyCardError />
      if (isLoading) return <SuggesterfyCardLoading />
      if (!data.length) return (
        <div className='w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7'>
          No Support Question
        </div>
      )

      return data.map((request, index) => (
        <UserSupportCard 
          key={index}
          request={request}
          lastIndex={data.length - 1 === index}
          isSelected={request.id === selectedRequest?.id}
          onClick={() => handleClickCard(request)}
        />
      ))
  }

  return (
    <div className='w-full pb-[20em]'>
      { renderSupportRequests() }
    </div>
  )
}

export default SupportQuestions