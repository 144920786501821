import { useNavigate } from 'react-router-dom'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enum'
import { openModal } from '../../../features/modal/modalSlice'
import { postReportState } from '../../../features/report/reportSlice'
import { selectUserProfile } from '../../../features/user/userSlice'
import PostImageSlider from '../../molecules/PostImageSlider'
import SectionTitle from '../../molecules/SectionTitle'
import UserProfile from '../../molecules/UserProfile'
import DOMPurify from 'dompurify'

const ReportedPost = () => {
  const { id, post, user, reportName } = useSuggesterfySelector(postReportState)
  const navigate = useNavigate()
  const dispatch = useSuggesterfyDispatch()
  const deleteModalState = {
    modalType: MODAL_TYPES.DELETE_REPORT,
    reportId: id
  }

  const keepModalState = {
    modalType: MODAL_TYPES.KEEP_REPORT,
    reportId: id
  }

  const visitPostUserProfile = () => {
    navigate('/users')
    dispatch(selectUserProfile(post.user))
  }

  const visitReporterProfile = () => {
    navigate('/users')
    dispatch(selectUserProfile(user))
  }

  const content = DOMPurify.sanitize(post.content)

  return (
    <div
      className='w-[95%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden
      mx-4 mt-[136px] mb-5'
    >
      {user.id
      ? <>
          <SectionTitle 
            page='reported_post'
            title='Reported Post'
            btnTitle='Delete Post'
            btnFunc={() => dispatch(openModal(deleteModalState))}
            keepPost={() => dispatch(openModal(keepModalState))}
          />
          <div className='h-full overflow-auto pb-[90px]'>
            <p className='text-xl ml-7 mt-4'>
            Original Post
            </p>
            <UserProfile 
              avatar={post.user.avatar}
              firstName={post.user.firstName}
              lastName={post.user.lastName}
              btnFunc={visitPostUserProfile}
            />
            <div className='flex px-7 items-center'>
              <p className='text-xl font-bold text-SuggesterfyRed'>Q</p>
              <div 
                className='pl-4 font-light tracking-wider'
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>
            <PostImageSlider images={post.postImages}/>
            <hr className='suggesterfy-border mt-3'/>
            <p className='text-xl ml-7 mt-4'>
            Post Reporter
            </p>
            <UserProfile 
              avatar={user.avatar}
              firstName={user.firstName}
              lastName={user.lastName}
              btnFunc={visitReporterProfile}
            />
            <div className='flex px-7 pb-3 text-SuggesterfyRed items-center'>
              <p className='text-xl font-semibold'>
                Reported For
              </p>
              <p className='text-lg ml-4'>
                {reportName}
              </p>
            </div>
          </div>
        </>
      : <div className='w-full h-full flex justify-center items-center text-SuggesterfyDark opacity-70'>
        Select a report to see details
        </div>
      }
    </div>
  )
}

export default ReportedPost