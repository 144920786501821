import { RFC } from '../../types/reactTypes'
import { MessageFieldProps } from '../../types/propTypes'
import { MessageButton } from '../../assets/icons/icons'
import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyMessageField:RFC<MessageFieldProps> = ({ 
    text, 
    isLoading,
    changeText, 
    onClick 
}) => {

    return (
        <div className='flex last:items-end bg-SuggesterfyMiddleGrey rounded-[10px]'>
            <textarea 
                rows={4}
                className='w-full h-[115px] bg-SuggesterfyMiddleGrey rounded-l-[10px] rounded-bl-[10px]
                px-3 py-2 outline-none resize-none'
                placeholder='Write a comment...'
                onChange={(e) => changeText(e.target.value) }
            />
            <button 
                className='w-10 h-10 mx-2 mb-2 flex justify-center items-center bg-SuggesterfyRed rounded-[5px]
                disabled:bg-SuggesterfyPink disabled:cursor-not-allowed hover:bg-[#E13724]
                transition-all duration-300'
                disabled={!text || isLoading}
                onClick={onClick}
            >
                {isLoading
                ? <SuggesterfyLoading shape='circle'/>
                : <MessageButton />
                }
            </button>
        </div>
    )
}

export default SuggesterfyMessageField