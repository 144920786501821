import { useEffect, useState } from 'react'
import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useLazyGetUserQuestionsQuery } from '../../../services/UserService'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { postsState, setQuestions, clearPosts, setInitialPosts } from '../../../features/user/postsSlice'
import { Post } from '../../../types/stateTypes'
import UserPostsSkeleton from '../../molecules/Skeletons/UserPostsSkeleton'
import SuggesterfyCardError from '../../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../../atoms/SuggesterfyCardLoading'
import UserPostCard from '../../molecules/UserPostCard'
import InfiniteScroll from 'react-infinite-scroller'
import SuggesterfyLoading from '../../atoms/SuggesterfyLoading'
import { MODAL_TYPES } from '../../../assets/data/enum'
import { openModal } from '../../../features/modal/modalSlice'

const Questions:RFC<IdProps> = ({ id }) => {
  const take = 5
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ selectedQuestion, setSelectedQuestion ] = useState<Post>()

  const allQuestions = useSuggesterfySelector(postsState)
  const dispatch = useSuggesterfyDispatch()
  
  const [ getUserPost, { isLoading, isError, data } ] = useLazyGetUserQuestionsQuery()

  useEffect(() => {
    setSkip(0)
    loadMorePosts(5, 0)
  },[id])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMorePosts(take, skip)
  }

  const loadMorePosts = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const questions = await getUserPost({ 
        take, 
        skip, 
        userId:id 
      }).unwrap()
      
      if (!questions.length && skip === 0) dispatch(clearPosts())
      if (!questions || !questions.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialPosts(questions))
          setHasMore(true)
          setSkip(5)
        } else if (questions.length < take) {
          dispatch(setQuestions(questions))
          setHasMore(false)
        } else {
          dispatch(setQuestions(questions))
          setHasMore(true)
          setSkip(skip + 5)
        }
      }

      setTimeout(() => {
        setIsLoadMore(false)
      },1000)
    } catch (error) {
      console.log({error})
    }
  }

  const handleClickCard = (question:Post) => {
    const modalState = {
      modalType: MODAL_TYPES.USER_QUESTION,
      question,
    }
    setSelectedQuestion(question)
    dispatch(openModal(modalState))
  }

  const renderQuestions = () => {
    if (!data)     return <UserPostsSkeleton />
    if (isError)   return <SuggesterfyCardError />
    if (isLoading) return <SuggesterfyCardLoading />
    if (!allQuestions.posts.length) return (
      <div className='w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7'>
        No Question
      </div>
    )

    return allQuestions.posts.map((post, index) => (
      <UserPostCard 
        key={index}
        post={post}
        lastIndex={allQuestions.posts.length - 1 === index}
        isSelected={post.id === selectedQuestion?.id}
        onClick={() => handleClickCard(post)}
      />
    ))
  }

  return (
    <div className='w-full pb-[20em]'>
      <InfiniteScroll
        loadMore={fetchMoreData}
        hasMore={hasMore}
        useWindow={false}
        loader={<SuggesterfyLoading shape='circle' key={0}/>}
        threshold={150}
      >
        { renderQuestions() }
      </InfiniteScroll>
    </div>
  )
}

export default Questions