import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyCardLoading = () => {
    return (
        <div
            className='felx justify-center items-center text-center mx-auto w-full p-5 mt-8'
        >
            <SuggesterfyLoading type='button' shape='bars'/>
            <p className='mt-4 text-SuggesterfyRed'>
            ... Loading
            </p>
        </div>
    )
}

export default SuggesterfyCardLoading