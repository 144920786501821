import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialRequestState } from '../../assets/data/initialStates'
import { ReturnSupport } from '../../types/serviceTypes'

export const requestSlice = createSlice({
    name: 'request',
    initialState: initialRequestState,
    reducers: {
        selectRequest: (state, action:PayloadAction<ReturnSupport>) => {
            const { id, title, body, user, userId } = action.payload
            state.id     = id
            state.title  = title
            state.body   = body
            state.user   = user
            state.userId = userId
        },
        clearRequest: (state) => {
            state.id           = ''
            state.title        = ''
            state.body         = ''
            state.userId       = ''
            state.user         = {
                id: '',
                avatar: '',
                firstName: '',
                lastName: '',
            }
            state.supportReply = []
        }
    }
})

export const requestState = (state: RootState) => state.request
export const {
    selectRequest,
    clearRequest,
} = requestSlice.actions
export default requestSlice.reducer