import { api } from '../app/api'
import { IdProps } from '../types/propTypes'
import { DeleteReport, PaginationPayload, RemoveReport, ReportCard } from '../types/serviceTypes'

export const reportApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPostReport: builder.query<ReportCard, PaginationPayload>({
            query: ({ take, skip }) => ({
                url: `/admin/reports`,
                params: { take, skip }
            }),
            providesTags: ['Report']
        }),
        deletePost: builder.mutation<DeleteReport, IdProps>({
            query: ({ id }) => ({
                url: `/admin/report-post/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Report']
        }),
        keepPost: builder.mutation<RemoveReport, IdProps>({
            query: ({ id }) => ({
                url: `/admin/reports/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Post']
        })
    })
})

export const { 
    useLazyGetPostReportQuery,
    useDeletePostMutation,
    useKeepPostMutation,
} = reportApi