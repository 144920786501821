import { ChildrenProp } from '../../types/propTypes'
import { RFC } from '../../types/reactTypes'

const Main:RFC<ChildrenProp> = ({ children }) => {
  return (
    <div
      className='w-[85%] h-screen bg-SuggesterfyLightGrey
      grid grid-rows-1 grid-cols-[40%_60%] overflow-hidden'
    >
      { children }
    </div>
  )
}

export default Main