import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: 'cors',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('SuggesterfyAdminToken')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 })

export const api = createApi({
    reducerPath: 'suggesterfyApi',
    baseQuery: baseQueryWithRetry,
    tagTypes: [ 'Support', 'Report', 'User', 'Post', 'Message'],
    endpoints: () => ({})
})