import { Dialog } from "@headlessui/react"
import { useSuggesterfyDispatch } from "../../../app/hooks"
import { SupportIcon } from "../../../assets/icons/icons"
import { closeModal } from "../../../features/modal/modalSlice"
import SuggesterfyDashboardButton from "../../atoms/SuggesterfyDashboardButton"

const SendMessageModal = () => {
  const dispatch = useSuggesterfyDispatch()
  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SupportIcon />
          <p className='text-xl ml-2'>
          Successfully sent your message
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description>
        <span className='my-4 text-center text-lg'>
        You have successfully sent your message.
        </span>
        <span className='mb-4 text-center text-lg'>
        This ticket has been dismissed.
        </span>
      </Dialog.Description>
    </>
  )
}

export default SendMessageModal