import { RFC } from '../../types/reactTypes'
import { UserProfileProps } from '../../types/propTypes'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const UserInfo:RFC<UserProfileProps> = ({ avatar, firstName, lastName }) => {
    return (
      <>
        <SuggesterfyAsyncAvatar imageKey={avatar} size='medium'/>
        <p className='text-xl font-semibold ml-4'>
          {firstName}
        </p>
        <p className='text-xl font-semibold ml-2'>
          {lastName}
        </p>
      </>
    )
}

export default UserInfo