import { useEffect, useRef, useState } from 'react'
import { RFC } from '../../types/reactTypes'
import { PostImageProps } from '../../types/propTypes'
import { ChevronLeft, ChevronRight, EmptyImageIcon } from '../../assets/icons/icons'
import { useDownloadImageMutation } from '../../services/Files'

const PostImageSlider:RFC<PostImageProps> = ({ images }) => {
    const [ imageUrls, setImageUrls ] = useState<string[]>([])
    const [ isImageError, setIsImageError ] = useState(false)
    const [ scrollX, setscrollX ] = useState(0)
    const [ scrolEnd, setscrolEnd ] = useState(false)
    const scrl = useRef<HTMLDivElement>(null)
    const imageKeys = images.map(image => image.imageKey)
    const [ getDownloadImage ] = useDownloadImageMutation()

    useEffect(() => {
        setImageUrls([])
        if (!imageKeys) return
        imageKeys.map(key => getPresignedUrl(key))
    },[images])

    useEffect(() => {
        if (
        scrl.current &&
        scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
        setscrolEnd(true)
        } else {
        setscrolEnd(false)
        }
        return () => {}
    },[scrl?.current?.scrollWidth, scrl?.current?.offsetWidth])

    const slide = (shift:number) => {
        if (scrl.current === null) return
        scrl.current.scrollLeft += shift
        setscrollX(scrollX + shift)
        if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
        ) {
            setscrolEnd(true)
        } else {
            setscrolEnd(false)
        }
    }

    const scrollCheck = () => {
        if (scrl.current === null) return
        setscrollX(scrl.current.scrollLeft)
        if (
          Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
          scrl.current.offsetWidth
        ) {
          setscrolEnd(true)
        } else {
          setscrolEnd(false)
        }
    }

    const getPresignedUrl = async (imageKey: string) => {
        if(!imageKey) return
        try {
            const presignedUrl = await getDownloadImage(imageKey).unwrap()
            setImageUrls(prev => {
                const filteredDuplicates = prev.filter(key => !key.includes(presignedUrl))
                return [...filteredDuplicates, presignedUrl]
            })   
        } catch (error) {
            console.log({error})
        }
    }

    return (
        <>
            {images.length === 0
            ? 
                <p 
                    className='px-7 py-3 flex justify-center text-SuggesterfyDark opacity-50'
                >
                This post has no image
                </p>
            : 
            <div 
                className='image-slider'
                ref={scrl}
                onScroll={scrollCheck}
            >
                {scrollX !== 0 && (
                <div 
                    className='bg-SuggesterfyGrey w-8 h-8 flex justify-center items-center rounded-[50%] sticky top-[40%] -left-6 cursor-pointer'
                    onClick={() => slide(-100)}
                >
                    <ChevronLeft />
                </div>
                )}
                {imageUrls.map((image, index) => (
                <div className='image-container' key={index}>
                    {isImageError
                    ?   
                    <div className='w-full h-full bg-SuggesterfyGrey flex justify-center items-center'>
                        <EmptyImageIcon 
                            width={48} 
                            height={48}
                        />
                    </div>
                    :
                    <img 
                        className='image-element'
                        src={image}
                        alt={'reported post'}
                        onClick={() => window.open(image, '_blank')}
                        onError={() => setIsImageError(true)}
                    />
                    }
                </div>
                ))}
                {!scrolEnd && (
                <div 
                    className='bg-SuggesterfyGrey w-8 h-8 flex justify-center items-center rounded-[50%] sticky top-[40%] -right-6 cursor-pointer'
                    onClick={() => slide(100)}
                >
                    <ChevronRight />
                </div>
                )}
            </div>
            }
        </>
    )
}

export default PostImageSlider