interface Props extends React.PropsWithChildren {
	className?: string;
	isActive?: boolean;
	onClick?: () => void;
}

const CalendarCell: React.FC<Props> = ({ onClick, children, className, isActive = false }) => {
	return (
		<div
			onClick={!isActive ? onClick : undefined}
			className={`h-10 flex items-center justify-center select-none transition-colors ${className}`}>
			{children}
		</div>
	);
};

export default CalendarCell;
