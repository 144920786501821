import { useEffect, useState } from "react";
import { RFC } from "../../types/reactTypes";
import { RenderUsersProps } from "../../types/propTypes";
import {
  useSuggesterfyDispatch,
  useSuggesterfySelector,
} from "../../app/hooks";
import { selectUser, userState } from "../../features/user/userSlice";
import {
  clearUsers,
  setInitialUsers,
  setUsers,
  usersState,
} from "../../features/user/usersSlice";
import { useLazyGetAllUsersQuery } from "../../services/UserService";
import SuggesterfyCardError from "../atoms/SuggesterfyCardError";
import SuggesterfyCardLoading from "../atoms/SuggesterfyCardLoading";
import SuggesterfyLoading from "../atoms/SuggesterfyLoading";
import UserCardSkeleton from "./Skeletons/UserCardSkeleton";
import UserCard from "./UserCard";
import InfiniteScroll from "react-infinite-scroller";
import spaceConverter from "../../utils/spaceConverter";

const RenderAllUsers: RFC<RenderUsersProps> = ({ searchText }) => {
  const take = 10;
  const [skip, setSkip] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const allUsers = useSuggesterfySelector(usersState);
  const { id } = useSuggesterfySelector(userState);
  const dispatch = useSuggesterfyDispatch();

  const [
    getAllUsers,
    { isLoading: userLoading, isError: userError, data: userData },
  ] = useLazyGetAllUsersQuery();

  useEffect(() => {
    setSkip(0);
    loadMoreUsers(10, 0);
  }, [searchText]);

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreUsers(take, skip);
  };

  const loadMoreUsers = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const users = await getAllUsers({
        take,
        skip,
        search: spaceConverter(searchText),
      }).unwrap();

      console.log("USERS", JSON.stringify(users, null, 3));

      if (!users.length && skip === 0) dispatch(clearUsers());
      if (!users || !users.length) {
        setHasMore(false);
      } else {
        if (skip === 0) {
          dispatch(setInitialUsers(users));
          setHasMore(true);
          setSkip(10);
        } else if (users.length < take) {
          dispatch(setUsers(users));
          setHasMore(false);
        } else {
          dispatch(setUsers(users));
          setHasMore(true);
          setSkip(skip + 10);
        }
      }

      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    } catch (error) {
      console.log({ error });
    }
  };

  const renderAllUsers = () => {
    if (userLoading) return <SuggesterfyCardLoading />;
    if (!userData) return <UserCardSkeleton />;
    if (userError) return <SuggesterfyCardError />;
    if (!allUsers.users.length)
      return (
        <div className="w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7">
          No User
        </div>
      );

    return allUsers.users.map((user, index) => (
      <UserCard
        key={index}
        user={user}
        lastIndex={allUsers.users.length - 1 === index}
        isSelected={user.id === id}
        onSelectUser={() => dispatch(selectUser(user))}
      />
    ));
  };

  return (
    <>
      <InfiniteScroll
        loadMore={fetchMoreData}
        hasMore={hasMore}
        useWindow={false}
        loader={<SuggesterfyLoading shape="circle" key={0} />}
        threshold={150}
      >
        {renderAllUsers()}
      </InfiniteScroll>
    </>
  );
};

export default RenderAllUsers;
