import React from "react";
import { LabelValue, User } from "../types/stateTypes";
import UserDetailsLabelValue from "./UserDetailsLabelValue";
import moment from "moment";

type Props = {
  userDetails: User;
};

const UserDetailsCard = ({ userDetails }: Props) => {
  const labelValuePairs: LabelValue[] = [
    { label: "First Name", value: userDetails.firstName },
    { label: "Last Name", value: userDetails.lastName },
    { label: "Mobile No.", value: userDetails.mobile ?? "Not available" },
    { label: "Email", value: userDetails.email ?? "Not available" },
    {
      label: "Date Joined",
      value: moment(userDetails.createdAt).format("DD.mm.YY"),
    },
  ];
  return (
    <div className="flex flex-col my-1 py-4 px-7">
      {labelValuePairs.map((labelValue, index) => {
        return <UserDetailsLabelValue labelValue={labelValue} key={index} />;
      })}
    </div>
  );
};

export default UserDetailsCard;
