import React, { useEffect, useRef, useState } from 'react';
import SuggesterfyDashboardButton from '../atoms/SuggesterfyDashboardButton';
import Calendar from './Calendar';
import { ChevronDown, ChevronUp } from '../../assets/icons/icons';
import { DateRange, ExportUserInfo } from '../../types/stateTypes';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useSuggesterfyDispatch } from '../../app/hooks';
import { closeSnackbar, openSnackbar } from '../../features/snackbar/snackbarSlice';
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../assets/data/enum';
import { useLazyExportUserDataQuery } from '../../services/UserService';
import { CSV_HEADERS } from '../../assets/data/constants';
import SuggesterfyLoading from '../atoms/SuggesterfyLoading';

const ExportUsers = () => {
	const dispatch = useSuggesterfyDispatch();
	const [exportUsers] = useLazyExportUserDataQuery();
	const [csvData, setCSVData] = useState<ExportUserInfo[]>([]);
	const [dateRange, setDateRange] = useState<DateRange | null>(null);
	const [isCalendarDisplayed, setIsCalendarDisplayed] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	const csvBtnRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

	const handleExport = async () => {
		if (!dateRange || !dateRange.startDate || !dateRange.endDate) return;
		const { startDate, endDate } = dateRange;
		setIsLoading(true);
		try {
			const { data } = await exportUsers({
				startDate: moment(startDate).format('YYYY-MM-DD'),
				endDate: moment(endDate).format('YYYY-MM-DD'),
			}).unwrap();
			setCSVData(data);
			setTimeout(() => {
				csvBtnRef.current?.link.click();
				setIsLoading(false);
			}, 1200);
			return;
		} catch (error) {
			dispatch(
				openSnackbar({
					type: SNACKBAR_TYPES.ERROR,
					message: ERROR_MESSAGES.EXPORT,
					timeout: 5000,
					btnText: SNACKBAR_BUTTON_TYPES.DISMISS,
					btnFunc: () => dispatch(closeSnackbar()),
				}),
			);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		console.log(dateRange);
	}, [dateRange]);

	return (
		<div className="flex items-center justify-end mr-12 translate-y-6 gap-3 relative z-50">
			<div className="flex items-center gap-3">
				<p className="text-base">
					All user data{' '}
					<span className="text-SuggesterfyRed">
						{moment(dateRange?.startDate).format('D MMM YYYY')}{' '}
					</span>
					-
					<span className="text-SuggesterfyRed">
						{' '}
						{moment(dateRange?.endDate).format('D MMM YYYY')}
					</span>
				</p>
				<button onClick={() => setIsCalendarDisplayed(!isCalendarDisplayed)}>
					{isCalendarDisplayed ? <ChevronUp /> : <ChevronDown />}
				</button>
			</div>
			{isLoading ? (
				<SuggesterfyLoading shape="circle" color="SuggesterfyRed" />
			) : (
				<SuggesterfyDashboardButton
					text="Export"
					btnStyle="outlined"
					onClick={handleExport}
					isDisabled={!dateRange}
				/>
			)}
			<CSVLink
				ref={csvBtnRef}
				data={csvData}
				headers={CSV_HEADERS}
				className="hidden"
				filename="Suggesterfy User List"
			/>
			{isCalendarDisplayed && (
				<div className="absolute right-0 top-0 -translate-x-24 translate-y-10">
					<Calendar
						date={currentDate}
						onChangeDate={(date: Date) => setCurrentDate(date)}
						onClose={() => setIsCalendarDisplayed(false)}
						onSubmit={(range: DateRange) => {
							if (range.startDate && range.endDate) {
								setDateRange(range);
							}
							setIsCalendarDisplayed(false);
						}}
						dateRange={dateRange}
					/>
				</div>
			)}
		</div>
	);
};

export default ExportUsers;
