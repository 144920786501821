import { api } from '../app/api'
import { IdProps } from '../types/propTypes'
import { PaginationPayload, ResponseRequest, SupportCard, SupportDismiss, SupportReply } from '../types/serviceTypes'

export const supportApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSupportRequests: builder.query<SupportCard, PaginationPayload>({
            query: ({ take, skip }) => ({
                url: `/admin/support`,
                params: {take, skip}
            }),
            providesTags: ['Support']
        }),
        dismissRequest: builder.mutation<SupportDismiss, IdProps>({
            query: ({ id }) => ({
                url: `/admin/support/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Support'],
        }),
        responseRequest: builder.mutation<SupportReply, ResponseRequest>({
            query: ({ supportId, message }) => ({
                url: `/admin/reply-support/${supportId}`,
                method: 'POST',
                body: { message }
            }),
            invalidatesTags: ['Support'],
        })
    })  
})

export const { 
   useLazyGetSupportRequestsQuery,
   useDismissRequestMutation, 
   useResponseRequestMutation,
} = supportApi