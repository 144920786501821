import { useState } from 'react';
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks';
import { userState } from '../../../features/user/userSlice';
import BanUser from '../../molecules/BanUser';
import SectionTitle from '../../molecules/SectionTitle';
import UserInfo from '../../molecules/UserInfo';
import { MODAL_TYPES, OVERVIEW_PILLS } from '../../../assets/data/enum';
import UserOverViewTab from '../../organisms/UserOverViewTab';
import Messages from '../../organisms/Overview/Messages';
import Questions from '../../organisms/Overview/Questions';
import Suggestions from '../../organisms/Overview/Suggestions';
import SupportQuestions from '../../organisms/Overview/SupportQuestions';
import ReportedPosts from '../../organisms/Overview/ReportedPosts';
import { openModal } from '../../../features/modal/modalSlice';
import UserDetails from '../../organisms/Overview/UserDetails';

const UserOverviewPanel = () => {
	const { id, avatar, firstName, lastName, bannedUser, user } = useSuggesterfySelector(userState);
	const [selectedTab, setSelectedTab] = useState(OVERVIEW_PILLS.MESSAGES);
	const dispatch = useSuggesterfyDispatch();
	const modalState = {
		modalType: MODAL_TYPES.CONTACT_USER,
		userId: id,
	};

	const renderOverviewPage = () => {
		switch (selectedTab) {
			case OVERVIEW_PILLS.MESSAGES:
				return <Messages id={id} />;
			case OVERVIEW_PILLS.QUESTIONS:
				return <Questions id={id} />;
			case OVERVIEW_PILLS.SUGGESTIONS:
				return <Suggestions id={id} />;
			case OVERVIEW_PILLS.SUPPORT_QUESTIONS:
				return <SupportQuestions id={id} />;
			case OVERVIEW_PILLS.REPORTED_POSTS:
				return <ReportedPosts id={id} />;
			case OVERVIEW_PILLS.USER_DETAILS:
				return <UserDetails id={id} />;
			default:
				return;
		}
	};

	return (
		<div
			className="w-[95%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden
    mx-4 mt-[45px] mb-5">
			{id ? (
				<>
					<SectionTitle
						page="user_overview"
						title="User Overview"
						btnTitle="Contact User"
						btnFunc={() => dispatch(openModal(modalState))}
					/>
					<div className="flex items-center my-1 px-7 py-4">
						<UserInfo avatar={avatar} firstName={firstName} lastName={lastName} />
					</div>
					<BanUser id={id} bannedUser={bannedUser} user={user} />
					<UserOverViewTab selectedTab={selectedTab} onSelect={setSelectedTab} />
					<div className="w-full h-full overflow-y-auto overflow-x-hidden">
						{renderOverviewPage()}
					</div>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center text-SuggesterfyDark opacity-70">
					Select a user to see details
				</div>
			)}
		</div>
	);
};

export default UserOverviewPanel;
