import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialModalState } from '../../assets/data/initialStates'
import { ModalPayload } from '../../types/stateTypes'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: initialModalState,
    reducers: {
        openModal: (state, action:PayloadAction<ModalPayload>) => {
            state.isDisplayed    = true
            state.modalType      = action.payload.modalType
            state.requestId      = action.payload.requestId
            state.reportId       = action.payload.reportId
            state.userId         = action.payload.userId
            state.postId         = action.payload.postId
            state.banType        = action.payload.banType
            state.message        = action.payload.message
            state.question       = action.payload.question
            state.suggestion     = action.payload.suggestion
            state.request        = action.payload.request
            state.report         = action.payload.report
        },
        closeModal: (state) => {
            state.isDisplayed = false
        }
    }
})

export const modalState = (state:RootState) => state.modal
export const { openModal, closeModal } = modalSlice.actions
export default modalSlice.reducer