import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialBannedUserState } from '../../assets/data/initialStates'
import { BannedUser, UserId } from '../../types/serviceTypes'

export const bannedUsersSlice = createSlice({
    name: 'users',
    initialState: initialBannedUserState,
    reducers: {
        clearBannedUsers: (state) => {
            state.users = []
        },
        setInitialBannedUsers: (state, action:PayloadAction<BannedUser[]>) => {
            state.users = action.payload
        },
        setBannedUsers: (state, action:PayloadAction<BannedUser[]>) => {
            const bannedUserIds = state.users.map(user => user.id)
            const filteredDuplicates = action.payload.filter(user => !bannedUserIds.includes(user.id))
            filteredDuplicates.map(user => state.users.push(user))
        },
        deleteBannedUser: (state, action:PayloadAction<UserId>) => {
            const filteredBannedUsers = state.users.filter(user => user.userId !== action.payload.userId)
            state.users = filteredBannedUsers
        }
    }
})
export const bannedUsersState = (state: RootState) => state.bannedUsers
export const {
    clearBannedUsers,
    setInitialBannedUsers,
    setBannedUsers,
    deleteBannedUser,
} = bannedUsersSlice.actions
export default bannedUsersSlice.reducer