import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import CalendarCell from '../atoms/CalendarCell';
import { ChevronLeft, ChevronRight } from '../../assets/icons/icons';
import { DateRange } from '../../types/stateTypes';
import CalendarFooter from '../atoms/CalendarFooter';

type Props = {
	date: Date;
	onChangeDate: (date: Date) => void;
	onClose: () => void;
	onSubmit: (dateRange: DateRange) => void;
	dateRange: DateRange | null;
};

const DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const Calendar = ({ date, onChangeDate, onClose, onSubmit, dateRange }: Props) => {
	const [localDateRange, setLocalDateRange] = useState<DateRange | null>(dateRange);
	const daysInMonth = moment(date).daysInMonth();
	const prefixDays = moment(date).startOf('month').day();
	const suffixDays = 6 - moment(date).endOf('month').day();
	const firstDayOfMonth = moment(date).startOf('month');

	const getPrevMonth = () => onChangeDate(moment(date).subtract(1, 'month').toDate());
	const getNextMonth = () => onChangeDate(moment(date).add(1, 'month').toDate());

	const allDates = Array.from({ length: daysInMonth }, (_, index) =>
		firstDayOfMonth.clone().add(index, 'days'),
	);

	const handleDateClick = (date: Moment) => {
		if (!localDateRange) {
			setLocalDateRange({ startDate: date.toDate(), endDate: null });
			return;
		}
		if (localDateRange.startDate) {
			if (moment(date).isBefore(localDateRange.startDate)) return;
			setLocalDateRange({ ...localDateRange, endDate: date.toDate() });
			return;
		}
		return;
	};

	return (
		<div className="w-[300px] bg-SuggesterfyWhite shadow-xl rounded-lg pt-2">
			<div className="px-3">
				<div className="grid grid-cols-7 items-center justify-center text-center">
					<CalendarCell className="col-span-3 font-semibold text-base -translate-x-4">
						{moment(date).format('YYYY MMM')}
						<ChevronRight className="text-SuggesterfyRed w-1" />
					</CalendarCell>
					<CalendarCell />
					<div className="col-span-3 flex justify-end gap-5">
						<button onClick={getPrevMonth}>
							<ChevronLeft className="text-SuggesterfyRed" />
						</button>
						<button onClick={getNextMonth}>
							<ChevronRight className="text-SuggesterfyRed" />
						</button>
					</div>

					{DAYS_OF_WEEK.map(day => {
						return (
							<CalendarCell key={day} className="uppercase text-sm text-gray-400">
								{day}
							</CalendarCell>
						);
					})}

					{Array.from({ length: prefixDays }).map((_, index) => (
						<CalendarCell key={index} />
					))}

					{allDates.map((moment, index) => {
						const date = index + 1;
						const isStartDate = moment.isSame(localDateRange?.startDate);
						const isEndDate = moment.isSame(localDateRange?.endDate);
						const isBetween = moment.isBetween(
							localDateRange?.startDate,
							localDateRange?.endDate,
						);

						const cellClassName = `text-[20px] cursor-pointer ${
							isStartDate ? 'bg-SuggesterfyRed rounded-full text-white' : ''
						} ${isEndDate ? 'rounded-full border-SuggesterfyRed border-2' : ''} ${
							isBetween ? 'bg-red-100' : ''
						}`;

						return (
							<CalendarCell
								key={date}
								className={cellClassName}
								onClick={() => handleDateClick(moment)}>
								{date}
							</CalendarCell>
						);
					})}

					{Array.from({ length: suffixDays }).map((_, index) => (
						<CalendarCell key={index} />
					))}
				</div>
			</div>
			<CalendarFooter
				onClear={() => setLocalDateRange(null)}
				onClose={onClose}
				onSubmit={() => {
					if (!localDateRange) return onClose();
					onSubmit(localDateRange);
					onClose();
				}}
			/>
		</div>
	);
};

export default Calendar;
