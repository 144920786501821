import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { ReportIcon } from '../../../assets/icons/icons'
import { closeModal, modalState } from '../../../features/modal/modalSlice'
import { deleteUserPost } from '../../../features/user/postsSlice'
import { useDeleteUserPostMutation } from '../../../services/UserService'
import asyncTimeout from '../../../utils/asyncTimeout'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'
import SuggesterfyLoading from '../../atoms/SuggesterfyLoading'
import { closeSnackbar, openSnackbar } from '../../../features/snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enum'

const UserDeletePostModal = () => {
    const [ isProcessing, setIsProcessing ] = useState(false)
    const postState = useSuggesterfySelector(modalState)
    const dispatch = useSuggesterfyDispatch()
    const [ deleteUserPostMutation, { isLoading, isError } ] = useDeleteUserPostMutation()

    const handleDelete = async () => {
        if (!postState.postId || !postState.userId) return
        const postId = postState.postId
        const userId = postState.userId
        const userPostState = { postId, userId }
        try {
            setIsProcessing(true)
            const [ data ] = await Promise.all([
                deleteUserPostMutation(userPostState).unwrap(),
                asyncTimeout(1500)
            ])
            if (!data) return
            dispatch(deleteUserPost({postId}))
            dispatch(closeModal())
        } catch (error) {
          dispatch(openSnackbar({
            type: SNACKBAR_TYPES.ERROR,
            message: ERROR_MESSAGES.DELETE_POST,
            timeout: 5000,
            btnText: SNACKBAR_BUTTON_TYPES.RETRY,
            btnFunc: () => {
              dispatch(closeSnackbar())
              handleDelete()
            }
          }))
        } finally {
            setIsProcessing(false)
        }
    }

    const renderButtons = () => {
        if (isLoading || isProcessing) return <SuggesterfyLoading shape='circle' key={0}/>
        if (isError)   return (
        <p className='mt-4 text-SuggesterfyRed'>
            Something went wrong. Please try again later.
        </p>
        )
        return (
        <div className='flex items-center mt-4'>
            <div className='mr-4'>
            <SuggesterfyDashboardButton 
                text='Delete Post'
                btnStyle='filled'
                onClick={handleDelete}
            />
            </div>
        </div>
        )
    }
    
    return (
        <>
          <Dialog.Title className='flex items-center justify-between'>
            <div className='flex items-center'>
              <ReportIcon className='flex items-center'/>
              <p className='text-xl ml-2'>
              Delete User Post
              </p>
            </div>
            <SuggesterfyDashboardButton 
              text='Close'
              btnStyle='outlined'
              onClick={() => dispatch(closeModal())}
            />
          </Dialog.Title>
          <hr className='suggesterfy-divider'/>
          <Dialog.Description>
            <span className='mb-4'>
              Are you sure you want to delete this post?&nbsp;
            </span>
            <span className='mb-4'>
              By deleting this post you will be removing it permanently from the app
            </span>
          </Dialog.Description>
          { renderButtons() }
        </>
    )
}

export default UserDeletePostModal