import { RFC } from '../../types/reactTypes'
import { SupportReplyCardProps } from '../../types/propTypes'
import moment from 'moment'

const SupportReplyCard:RFC<SupportReplyCardProps> = ({ reply, lastIndex}) => {
    const repliedTime = moment(reply.createdAt).format('DD-MM-YYYY')
    return (
        <>
            <div className='flex items-center p-1 my-2'>
                <p className='text-xl font-semibold'>
                    Your Reply
                </p>
                <p className='font-medium ml-4 text-SuggesterfyDark/70'>
                    Sent on: {repliedTime}
                </p>
            </div>
            <div className='px-2 text-base'>
                {reply.message}
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default SupportReplyCard