import Sidebar from '../../components/templates/Sidebar';
import Main from '../../components/templates/Main';
import AllUsers from '../../components/templates/Users/AllUsers';
import UserOverview from '../../components/templates/Users/UserOverview';

const Users = () => {
	return (
		<div className="flex overflow-hidden">
			<Sidebar page="users" />
			<Main>
				<>
					<AllUsers />
					<UserOverview />
				</>
			</Main>
		</div>
	);
};

export default Users;
