import { RFC } from '../../types/reactTypes'
import { SearchUsersProps } from '../../types/propTypes'
import { SEARCH_PILLS } from '../../assets/data/enum'
import { SearchIcon } from '../../assets/icons/icons'
import SuggesterfyPill from '../atoms/SuggesterfyPill'
import { useSuggesterfyDispatch } from '../../app/hooks'
import { clearUser } from '../../features/user/userSlice'

const SearchUsers:RFC<SearchUsersProps> = ({ 
  selectedPill, 
  onSelectPill,
  onSearchChange,
}) => {
  const dispatch = useSuggesterfyDispatch()

  return (
    <div>
      <div className='flex justify-center px-6 py-4'>
        <input 
          type='text' 
          className='flex-auto min-w-0 w-full px-3 py-1.5 mr-3 outline-none border-none 
          rounded-[5px] m-0 text-base font-normal text-SuggesterfyDark bg-clip-padding
          bg-SuggesterfyMiddleGrey transition ease-in-out focus:shadow-[0_0_0_2px_#FA503D]'
          placeholder='Search'
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <button 
          type='button'
          className='min-w-[45px] min-h-[45px] flex justify-center items-center bg-SuggesterfyRed text-white font-medium text-xs 
          leading-tight uppercase rounded shadow-md hover:bg-[#E13724] hover:shadow-lg 
          focus:bg-[#E13724]  focus:shadow-lg focus:outline-none focus:ring-0 
          active:bg-SuggesterfyRed active:shadow-lg transition duration-150 ease-in-out' 
        >
          <SearchIcon />
        </button>
      </div>
      <div className='flex px-7 mb-4'>
        <SuggesterfyPill 
          title={SEARCH_PILLS.ALL} 
          isSelected={selectedPill === SEARCH_PILLS.ALL}
          onSelect={() => {
            onSelectPill(SEARCH_PILLS.ALL)
            dispatch(clearUser())
          }}
        />
        <SuggesterfyPill 
          title={SEARCH_PILLS.BANNED} 
          isSelected={selectedPill === SEARCH_PILLS.BANNED}
          onSelect={() => {
            onSelectPill(SEARCH_PILLS.BANNED)
            dispatch(clearUser())
          }}
        />
      </div>
      <hr className='suggesterfy-border'/>
    </div>
  )
}

export default SearchUsers