const chevronLeft = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? 24}
            height={height ?? 24}
            fill={color ?? 'none'} 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            strokeWidth="2"
        >
            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" d="M15 19l-7-7 7-7" 
            />
        </svg>
      </div>
    )
  }
  
  export default chevronLeft