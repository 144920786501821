import { RFC } from '../../types/reactTypes';
import { DashboardProps } from '../../types/propTypes';
import { SupportIcon, UsersIcon } from '../../assets/icons/icons';

const DashboardTitle: RFC<DashboardProps> = ({ page }) => {
	const renderTitle = () => {
		switch (page) {
			case 'support':
				return {
					icon: <SupportIcon className="mr-2" />,
					main: 'Notice Board',
					sub: 'Support',
				};
			case 'reports':
				return {
					icon: <SupportIcon className="mr-2" />,
					main: 'Notice Board',
					sub: 'Post Reports',
				};
			case 'users':
				return {
					icon: <UsersIcon className="mr-2" />,
					main: 'Users',
					sub: 'All Users',
				};
			default:
				return null;
		}
	};

	return (
		<div className="mb-5">
			<p className="text-lg">👋 Welcome Back</p>
			<div className="flex items-center my-2 text-2xl">
				{renderTitle()?.icon}
				<p className="font-bold">{renderTitle()?.main}</p>
				<p className="ml-3">{renderTitle()?.sub}</p>
			</div>
		</div>
	);
};

export default DashboardTitle;
