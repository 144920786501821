import { RFC } from '../../types/reactTypes'
import { ReportCardProps } from '../../types/propTypes'
import SuggesterfyAsyncImage from '../atoms/SuggesterfyAsyncImage'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const ReportCard:RFC<ReportCardProps> = ({
    report,
    lastIndex,
    isSelected,
    onClick
}) => {
    const { post, user, reportName } = report
    const topImage = post?.postImages[0]?.imageKey?.toString()

    return (
        <>
            <div
                className={isSelected
                    ? 'grid grid-rows-1 grid-cols-[80px_1fr] items-center w-full h-[110px] my-1 px-5 py-4 hover:bg-SuggesterfyLightPink cursor-pointer transition-all duration-200'
                    : 'grid grid-rows-1 grid-cols-[80px_1fr] items-center w-full h-[110px] my-1 px-5 py-4 hover:bg-SuggesterfyLightGrey cursor-pointer transition-all duration-200'
                }
                style={{background: isSelected ? '#FA503D1A' : undefined}}
                onClick={onClick}
            >
                <SuggesterfyAsyncImage 
                    imageKey={topImage} 
                    size='small'
                />
                <div className='flex flex-col justify-center ml-4'>
                    <div className='flex items-center'>
                        <SuggesterfyAsyncAvatar 
                            imageKey={post?.user.avatar}
                            size='small'
                        />
                        <p className='text-lg font-semibold ml-4'>
                            {post.user.firstName} {post.user.lastName}
                        </p>
                        <p className='text-lg text-SuggesterfyDark opacity-50 ml-4'>
                            {post.type}
                        </p>
                    </div>
                    <div className='text-SuggesterfyRed font-semibold text-xl mt-1'>
                        {reportName}
                    </div>
                </div>
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default ReportCard