import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useGetMessagesQuery } from '../../../services/UserService'
import { useState } from 'react'
import { Message } from '../../../types/stateTypes'
import UserMessageSkeleton from '../../molecules/Skeletons/UserMessageSkeleton'
import SuggesterfyCardError from '../../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../../atoms/SuggesterfyCardLoading'
import UserMessageCard from '../../molecules/UserMessageCard'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { openModal } from '../../../features/modal/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enum'
import moment from 'moment'

const Messages:RFC<IdProps> = ({ id }) => {
  const { isLoading, isError, data } = useGetMessagesQuery({ take:100, skip:0, userId:id })
  const [ selectedMessage, setSelectedMessage ] = useState<Message>()
  const dispatch = useSuggesterfyDispatch()
  
  const handleClickCard = (message:Message) => {
    const modalState = {
      modalType: MODAL_TYPES.USER_MESSAGE,
      message
    }
    setSelectedMessage(message)
    dispatch(openModal(modalState))
  }

  const renderMessage = () => {
    if (!data) return <UserMessageSkeleton />
    if (isError) return <SuggesterfyCardError />
    if (isLoading) return <SuggesterfyCardLoading />
    if (!data.length) return (
      <div className='w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7'>
        No Message
      </div>
    )
    
    const descendingOrder = data.slice().sort((a, b) => parseInt(moment(b.createdAt).format("X")) - parseInt(moment(a.createdAt).format("X")))
    return descendingOrder.map((message, index) => (
      <UserMessageCard 
        key={index}
        message={message}
        lastIndex={data.length - 1 === index}
        isSelected={message.id === selectedMessage?.id}
        onClick={() => handleClickCard(message)}
      />
    ))
  }

  return (
    <div className='w-full pb-[20em]'>
      { renderMessage() }
    </div>
  )
}

export default Messages