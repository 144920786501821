const chevronUp = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
	return (
		<div className={className}>
			<svg
				width="13"
				height="7"
				viewBox="0 0 13 7"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M6.12435 0.4681C6.33182 0.260633 6.66818 0.260633 6.87565 0.4681L12.1882 5.7806C12.3956 5.98807 12.3956 6.32443 12.1882 6.5319C11.9807 6.73937 11.6443 6.73937 11.4368 6.5319L6.5 1.59505L1.56315 6.5319C1.35568 6.73937 1.01932 6.73937 0.81185 6.5319C0.604383 6.32443 0.604383 5.98807 0.81185 5.7806L6.12435 0.4681Z"
					fill="#0A1229"
				/>
			</svg>
		</div>
	);
};

export default chevronUp;
