import { useEffect, useState } from 'react'
import { RFC } from '../../types/reactTypes'
import { ImageProps } from '../../types/propTypes'
import { useDownloadImageMutation } from '../../services/Files'
import { AvatarPlaceholder, ImageError } from '../../assets/icons/icons'
import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyAsyncAvatar:RFC<ImageProps> = ({ imageKey, size }) => {
    const [ imageUrl, setImageUrl ] = useState(imageKey ? '' : undefined)
    const [ isAvatarError, setIsAvatarError ] = useState(false)
    const [ getDownloadImage, { isLoading, isError } ] = useDownloadImageMutation()

    const getIconSize = () => {
        switch (size) {
            case 'small':   return 8
            case 'medium':  return 10
            case 'large':   return 12
            default:        return 0
        }
    }

    const getPlaceholderSize = () => {
        switch (size) {
            case 'small':   return 40
            case 'medium':  return 48
            case 'large':   return 56
            default:        return 0
        }
    }

    useEffect(() => {
        getPresignedUrl()
        setIsAvatarError(false)
    },[imageKey])

    const getPresignedUrl = async () => {
        if (!imageKey) return
        try {
            const presignedUrl = await getDownloadImage(imageKey).unwrap()
            setImageUrl(presignedUrl)
        } catch (error) {
            console.log({error})
        }
    }

    const renderUserAvatar = () => {
        if (imageUrl === undefined) return (
            <AvatarPlaceholder 
                width={getPlaceholderSize()}
                height={getPlaceholderSize()}
                className={`overflow-hidden relative w-${getIconSize()} h-${getIconSize()} bg-gray-100 rounded-full dark:bg-gray-300 shadow-lg`}
            />
        )
        if (isLoading) return <SuggesterfyLoading shape='circle' />
        if (isError || isAvatarError) return <ImageError className={`p-1 w-${getIconSize()} h-${getIconSize()} bg-gray-200 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 shadow-lg`} />
        return (
            <img 
                className={`p-[2px] w-${getIconSize()} h-${getIconSize()} rounded-full ring-2 
                ring-gray-300 dark:ring-gray-500 shadow-lg object-cover`} 
                src={imageUrl} 
                alt='avatar'
                onError={() => setIsAvatarError(true)}
            />
        )
    }

    return (
        <>
        { renderUserAvatar() }
        </>
    )
}

export default SuggesterfyAsyncAvatar