import { RFC } from '../../types/reactTypes'
import { CommentCardProps } from '../../types/propTypes'
import moment from 'moment'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const CommentCard:RFC<CommentCardProps> = ({ comment, lastIndex }) => {
    const commentedTime = moment(comment.createdAt).format('HH:mm DD/MM/YYYY')
    return (
        <>
            <div className='flex items-center p-1 my-2'>
                <SuggesterfyAsyncAvatar imageKey={comment.user.avatar} size='medium'/>
                <p className='text-xl font-semibold ml-4'>
                    {comment.user.firstName}
                </p>
                <p className='text-xl font-semibold ml-2'>
                    {comment.user.lastName}
                </p>
                <p className='font-medium ml-4 text-SuggesterfyDark/70'>
                    {commentedTime}
                </p>
                <p className='font-medium ml-4 text-SuggesterfyDark/70'>
                    {comment._count.commentLikes} Votes
                </p>
            </div>
            <div className='px-2 text-base pb-3'>
                {comment.content}
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default CommentCard