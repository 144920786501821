import { useEffect, useState } from 'react'
import SectionTitle from '../molecules/SectionTitle'
import ReportCard from '../molecules/ReportCard'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../app/hooks'
import { postReportState, selectReport } from '../../features/report/reportSlice'
import { clearReports, reportsState, setInitialReports, setReports } from '../../features/report/reportsSlice'
import { useLazyGetPostReportQuery } from '../../services/ReportService'
import ReportCardSkeleton from '../molecules/Skeletons/ReportCardSkeleton'
import SuggesterfyCardError from '../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../atoms/SuggesterfyCardLoading'
import InfiniteScroll from 'react-infinite-scroller'
import SuggesterfyLoading from '../atoms/SuggesterfyLoading'

const PostReportsList = () => {
  const take = 5
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const dispatch = useSuggesterfyDispatch()
  const allReports = useSuggesterfySelector(reportsState).reports
  const { id } = useSuggesterfySelector(postReportState)
  const [ getPostReports, { isLoading, isError, data } ] = useLazyGetPostReportQuery()

  useEffect(() => {
    setSkip(0)
    loadMoreReports(5, 0)
  }, [])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreReports(take, skip)
  }

  const loadMoreReports = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const { reports } = await getPostReports({ take, skip }).unwrap()

      if (!reports.length && skip === 0) dispatch(clearReports())
      if (!reports || !reports.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialReports(reports))
          setHasMore(true)
          setSkip(5)
        } else if (reports.length < take) {
          dispatch(setReports(reports))
          setHasMore(false)
        } else {
          dispatch(setReports(reports))
          setHasMore(true)
          setSkip(skip + 5)
        }
      }
  
      setTimeout(() => {
        setIsLoadMore(false)
      }, 1000)
    } catch (error) {
      console.log({error})
    }
  }

  const renderReportCards = () => {
    if (isLoading) return <SuggesterfyCardLoading />
    if (!data)     return <ReportCardSkeleton />
    if (isError)   return <SuggesterfyCardError />
    if (!allReports.length) return (
      <div className="w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7">
        No Report
      </div>
    )
    
    return allReports.map((report, index) => (
      <ReportCard 
        key={index}
        report={report}
        lastIndex={allReports.length - 1 === index}
        isSelected={report.id === id}
        onClick={() => dispatch(selectReport(report))}
      />
    ))
  }

  return (
    <div className='w-full h-[87%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden'>
      <SectionTitle page='reports' title='Reports'/>
      <div className='w-full h-full overflow-y-auto overflow-x-hidden pb-[6em]'>
        <InfiniteScroll
          loadMore={fetchMoreData}
          hasMore={hasMore}
          useWindow={false}
          loader={<SuggesterfyLoading shape='circle' key={0}/>}
          threshold={150}
        >
          { renderReportCards() }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default PostReportsList