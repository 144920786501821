import React from 'react'
import { SnackbarError } from '../../assets/icons/icons'

const SuggesterfyCardError = () => {
    return (
        <div
            className='flex justify-center flex-col items-center '
        >
            <SnackbarError className='w-[3em] h-[3em] text-SuggesterfyRed mt-8'/>
            <p className='mt-4 text-SuggesterfyRed'>
                Something went wrong. Please try again later.
            </p>
        </div>
    )
}

export default SuggesterfyCardError