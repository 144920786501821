import { useEffect } from 'react'
import classes from '../../assets/styles/snackbar.module.css'
import { RFC } from '../../types/reactTypes'
import { SnackBarProps } from '../../types/propTypes'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../app/hooks'
import { snackbarSelector, closeSnackbar } from '../../features/snackbar/snackbarSlice'
import { CloseIcon, SnackbarError, SnackbarInfo, SnackbarSuccess, SnackbarWarning } from '../../assets/icons/icons'
import { SNACKBAR_TYPES } from '../../assets/data/enum'

const SuggesterfySnackbar: RFC<SnackBarProps> = ({ position }) => {
  const snackbarState = useSuggesterfySelector(snackbarSelector)
  const dispatch = useSuggesterfyDispatch()
  const {
    isDisplayed, snackbarType, snackbarMessage,
    snackbarTimeout, snackbarBtnText, snackbarBtnFunc
  } = snackbarState
  const TIME = `${(snackbarTimeout - 500) / 1000}s`

  useEffect(() => {
    if (isDisplayed) {
      const TIMER = setTimeout(() => {
        dispatch(closeSnackbar())
      }, snackbarTimeout)
      return () => clearTimeout(TIMER)
    }
  }, [isDisplayed])

  const getSnackbarIcons = () => {
    switch (snackbarType) {
      case SNACKBAR_TYPES.ERROR:
        return <SnackbarError className='min-w-[1.6em] h-full mr-4 text-white' />
      case SNACKBAR_TYPES.WARNING:
        return <SnackbarWarning className='min-w-[1.6em] h-full mr-4 text-black' />
      case SNACKBAR_TYPES.INFO:
        return <SnackbarInfo className='min-w-[1.6em] h-full mr-4 text-white' />
      case SNACKBAR_TYPES.SUCCESS:
        return <SnackbarSuccess className='min-w-[1.6em] h-full mr-4 text-white' />
      default: return
    }
  }

  return (
    <div
      className={`${classes.snackbar_container} ${isDisplayed && classes.snackbar_show} ${classes[position]} ${classes[snackbarType]} sm:w-[400px]`}
      style={{ animationDelay: `0s, ${TIME}` }}
    >
      {getSnackbarIcons()}
      <p className='pr-3 text-sm text-white'>
        {snackbarMessage}
      </p>
      <button
        data-mdb-ripple='true'
        data-mdb-ripple-color='light'
        className='px-4 py-2.5 text-sm bg-SuggesterfyRed flex justify-center items-center  
        text-SuggesterfyWhite rounded-[6px] hover:bg-[#E13724]'
        onClick={snackbarBtnFunc}
      >
        {snackbarBtnText}
      </button>
      <CloseIcon
        className='p-2 rounded-[50%] min-w-[40px] min-h-[40px] ml-4 fill-white cursor-pointer hover:bg-SuggesterfyRed'
        onClick={() => dispatch(closeSnackbar())}
      />
    </div>
  )
}

export default SuggesterfySnackbar