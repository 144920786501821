import DashboardTitle from '../../molecules/DashboardTitle';
import AllUsersList from '../../organisms/AllUsersList';

const AllUsers = () => {
	return (
		<div className="ml-10 mt-12">
			<DashboardTitle page="users" />
			<AllUsersList />
		</div>
	);
};

export default AllUsers;
