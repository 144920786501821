import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialUserState } from '../../assets/data/initialStates'
import { AllUserInfo, UserInfo } from '../../types/stateTypes'
import { BannedUser } from '../../types/serviceTypes'

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        selectUser: (state, action:PayloadAction<AllUserInfo>) => {
            const { id, avatar, firstName, lastName, bannedUser } = action.payload
            state.id         = id
            state.avatar     = avatar
            state.firstName  = firstName
            state.lastName   = lastName
            state.bannedUser = bannedUser
        },
        selectBannedUser: (state, action:PayloadAction<BannedUser>) => {
            const { id, firstName, lastName, user } = action.payload
            state.id         = id
            state.avatar     = user?.avatar
            state.firstName  = firstName
            state.lastName   = lastName
            state.user       = user
            state.bannedUser = {
                id: '',
                firstName: '',
                lastName: '',
                createdAt: new Date(),
                updatedAt: new Date(),
                userId: '',
                user: undefined
            }
        },
        selectUserProfile: (state, action:PayloadAction<UserInfo>) => {
            const { id, avatar, firstName, lastName } = action.payload
            state.id         = id
            state.avatar     = avatar
            state.firstName  = firstName
            state.lastName   = lastName
        },
        clearUser: (state) => {
            state.id         = ''
            state.avatar     = ''
            state.firstName  = ''
            state.lastName   = ''
            state.bannedUser = null
        }
    }
})

export const userState = (state: RootState) => state.user
export const {
    selectUser,
    selectBannedUser,
    selectUserProfile,
    clearUser,
} = userSlice.actions
export default userSlice.reducer