const searchUsersIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24">
            <g id="vuesax_outline_user-search" data-name="vuesax/outline/user-search" transform="translate(-428 -188)">
                <g id="user-search">
                <path id="Vector" d="M5.75,11.5A5.75,5.75,0,1,1,11.5,5.75,5.757,5.757,0,0,1,5.75,11.5Zm0-10A4.25,4.25,0,1,0,10,5.75,4.259,4.259,0,0,0,5.75,1.5Z" transform="translate(434.25 189.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-2" data-name="Vector" d="M.75,8.5A.755.755,0,0,1,0,7.75C0,3.48,4.19,0,9.34,0a.755.755,0,0,1,.75.75.755.755,0,0,1-.75.75C5.02,1.5,1.5,4.3,1.5,7.75A.755.755,0,0,1,.75,8.5Z" transform="translate(430.66 202.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-3" data-name="Vector" d="M3.95,7.9A3.95,3.95,0,1,1,7.9,3.95,3.952,3.952,0,0,1,3.95,7.9Zm0-6.4A2.45,2.45,0,1,0,6.4,3.95,2.454,2.454,0,0,0,3.95,1.5Z" transform="translate(442.25 202.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-4" data-name="Vector" d="M1.748,2.5a.742.742,0,0,1-.53-.22l-1-1A.75.75,0,0,1,1.278.218l1,1a.755.755,0,0,1,0,1.06A.742.742,0,0,1,1.748,2.5Z" transform="translate(448.252 208.252)" fill={color ?? '#292d32'}/>
                <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(428 188)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default searchUsersIcon