import { useState } from 'react'
import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { useKeepPostMutation } from '../../../services/ReportService'
import { closeModal } from '../../../features/modal/modalSlice'
import { clearReport } from '../../../features/report/reportSlice'
import { deleteReport } from '../../../features/report/reportsSlice'
import { Dialog } from '@headlessui/react'
import { ReportIcon } from '../../../assets/icons/icons'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'
import SuggesterfyLoading from '../../atoms/SuggesterfyLoading'
import asyncTimeout from '../../../utils/asyncTimeout'
import { closeSnackbar, openSnackbar } from '../../../features/snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enum'

const KeepReportModal:RFC<IdProps | undefined> = (id) => {
  const [ isProcessing, setIsProcessing ] = useState(false)
  const dispatch = useSuggesterfyDispatch()
  const [ keepPost, { isLoading, isError } ] = useKeepPostMutation()
  
  const handleKeep = async () => {
    if (!id) return
    try {
      setIsProcessing(true)
      const [ data ] = await Promise.all([
        keepPost(id).unwrap(),
        asyncTimeout(1500)
      ])
      if (!data) return
      dispatch(clearReport())
      dispatch(deleteReport(id))
      dispatch(closeModal())
    } catch (error) {
      dispatch(openSnackbar({
        type: SNACKBAR_TYPES.ERROR,
        message: ERROR_MESSAGES.KEEP_POST,
        timeout: 5000,
        btnText: SNACKBAR_BUTTON_TYPES.RETRY,
        btnFunc: () => {
          dispatch(closeSnackbar())
          handleKeep()
        }
      }))
    } finally {
      setIsProcessing(false)
    }
  }

  const renderButtons = () => {
    if (isLoading || isProcessing) return <SuggesterfyLoading shape='circle' key={0}/>
    if (isError)   return (
      <p className='mt-4 text-SuggesterfyRed'>
        Something went wrong. Please try again later.
      </p>
    )
    return (
      <div className='flex items-center mt-4'>
        <div className='mr-4'>
          <SuggesterfyDashboardButton 
            text='Keep Post'
            btnStyle='filled'
            onClick={handleKeep}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <ReportIcon className='flex items-center'/>
          <p className='text-xl ml-2'>
          Keep Reported Post
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description>
        <span className='mb-4'>
          Are you sure you want to keep this post?&nbsp;
        </span>
        <span className='mb-4'>
          By keeping this post you will be removing it permanently from the reported post list
        </span>
      </Dialog.Description>
      { renderButtons() }
    </>
  )
}

export default KeepReportModal