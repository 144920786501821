import { Dialog } from '@headlessui/react'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { SectionUserIcon } from '../../../assets/icons/icons'
import { closeModal } from '../../../features/modal/modalSlice'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'

const ContactConfirmedModal = () => {
  const dispatch = useSuggesterfyDispatch()
  
  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SectionUserIcon />
          <p className='text-xl ml-2'>
          Successfully sent your message
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description>
        <span className='my-4 w-[36rem] text-center text-lg'>
          You have successfully sent your message.
        </span>
      </Dialog.Description>
    </>
  )
}

export default ContactConfirmedModal