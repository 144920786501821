import Sidebar from '../../components/templates/Sidebar'
import Main from '../../components/templates/Main'
import PostReports from '../../components/templates/Reports/PostReports'
import ReportedPost from '../../components/templates/Reports/ReportedPost'

const Reports = () => {
  return (
    <div className='flex overflow-hidden'>
      <Sidebar page='reports' />
      <Main>
        <>
          <PostReports />
          <ReportedPost />
        </>
      </Main>
    </div>
  )
}

export default Reports