import { RFC } from "../../../types/reactTypes";
import { IdProps } from "../../../types/propTypes";
import { useGetUserDetailsQuery } from "../../../services/UserService";
import SuggesterfyCardError from "../../atoms/SuggesterfyCardError";
import SuggesterfyCardLoading from "../../atoms/SuggesterfyCardLoading";
import UserReportSkeleton from "../../molecules/Skeletons/UserReportSkeleton";
import { useSuggesterfyDispatch } from "../../../app/hooks";
import UserDetailsCard from "../../UserDetailsCard";

const UserDetails: RFC<IdProps> = ({ id }) => {
  const { isLoading, isError, data } = useGetUserDetailsQuery({
    userId: id,
  });
  const dispatch = useSuggesterfyDispatch();

  console.log("USER DETAILS", JSON.stringify(data, null, 2));

  const renderReportedPosts = () => {
    if (isError) return <SuggesterfyCardError />;
    if (isLoading) return <SuggesterfyCardLoading />;
    if (!data)
      return (
        <div className="w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7">
          No User Data Available
        </div>
      );

    return <UserDetailsCard userDetails={data} />;
  };

  return <div className="w-full pb-[20em]">{renderReportedPosts()}</div>;
};

export default UserDetails;
