import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useSuggesterfySelector } from './app/hooks'
import { loadingState } from './features/modal/banUserSlice'
import SuggesterfyModal from './components/atoms/SuggesterfyModal'
import SuggesterfySnackbar from './components/atoms/SuggesterfySnackbar'
import { Auth, Reports, Support, Users } from './pages/Pages'
import { AuthRequiredRoutes, NotAuthRoutes } from './utils/CustomRoutes'
import SuggesterfyBanUserLoading from './components/atoms/SuggesterfyBanUserLoading'

const App = () => {
  const banUserState = useSuggesterfySelector(loadingState)

  return (
    <Router>
      <SuggesterfySnackbar position='bottom_left'/>
      <SuggesterfyModal />
      {banUserState.isLoading && (<SuggesterfyBanUserLoading />)}
      <Routes>
        <Route path='/' element={<Navigate to='/login' replace/>} />
        <Route element={<NotAuthRoutes />}>
          <Route path='/login' element={<Auth />}/>
        </Route>
        <Route element={<AuthRequiredRoutes />}>
          <Route path='/support' element={<Support />}/>
          <Route path='/reports' element={<Reports />}/>
          <Route path='/users' element={<Users />}/>
        </Route>
        <Route path='*' element={<Auth />} />
      </Routes>
    </Router>
  )
}

export default App