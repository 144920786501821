import { AdminLogin, BannedUser, ReturnReport, ReturnSupport } from '../../types/serviceTypes'
import { AllUserInfo, BanUserLoading, Modal, Post, Snackbar, User, UserInfo } from '../../types/stateTypes'
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_TYPES } from './enum'

export const initialSnackbarState: Snackbar = {
    isDisplayed: false,
    snackbarType: SNACKBAR_TYPES.ERROR,
    snackbarMessage: ERROR_MESSAGES.LOGIN,
    snackbarTimeout: 10000,
    snackbarBtnText: 'CLOSE',
    snackbarBtnFunc: () => {}
}

export const initialModalState: Modal = {
    isDisplayed: false,
    modalType: MODAL_TYPES.CONTACT_USER,
    requestId: '',
    reportId: '',
    userId: '',
    postId: '',
    banType: '',
}

export const initialBanUserLoadingState: BanUserLoading = {
    isLoading: false,
    actionType: 'ban',
}

export const initialAdminLoginState: AdminLogin = {
    email: '',
    password: '',
}

export const initialRequestState: ReturnSupport = {
    id: '',
    title: '',
    body: '',
    userId: '',
    user: {
        id: '',
        avatar: '',
        firstName: '',
        lastName: '',
    },
    supportReply: [],
}

export const initialRequestsState: { requests: ReturnSupport[] } = {
    requests: []
}

export const initialReportState: ReturnReport = {
    id: '',
    reportName:'',
    postId: '',
    userId: '',
    post: {
        city: '',
        content: '',
        id: '',
        postImages: [],
        type: '',
        userId: '',
        user: {
            id: '',
            avatar: '',
            firstName: '',
            lastName: '',
        },
    },
    user: {
        id: '',
        avatar: '',
        firstName: '',
        lastName: '',
    },
}

export const initialReportsState: { reports: ReturnReport[] } = {
    reports: []
}

export const initialUserState: AllUserInfo = {
    id: '',
    avatar: '',
    firstName: '',
    lastName: '',
    bannedUser: null,
}

export const initialUsersState: { users: User[] } = {
    users: []
}

export const initialBannedUserState: { users: BannedUser[] } = {
    users: []
}

export const initialPostsState: { posts: Post[] } = {
    posts: []
}

