import { RFC } from '../../types/reactTypes'
import { ToggleProps } from '../../types/propTypes'
import { Switch } from '@headlessui/react'

const SuggesterfyToggleSwitch:RFC<ToggleProps> = ({ enabled, onChange }) => {
    return (
        <Switch
          checked={enabled}
          onChange={onChange}
          className={`${
            enabled ? 'bg-SuggesterfyGreen' : 'bg-SuggesterfyRed'
          } relative inline-flex h-8 w-14 items-center rounded-full border-[1px] border-solid border-SuggesterfyLightGrey`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              enabled 
              ? 'translate-x-6' 
              : '-translate-x-[1px]'
            } inline-block h-8 w-8 transform rounded-full bg-white border-[2px] border-solid border-SuggesterfyLightGrey transition-all duration-200`}
          />
        </Switch>
    )
}

export default SuggesterfyToggleSwitch