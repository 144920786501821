import DashboardTitle from '../../molecules/DashboardTitle'
import PostReportsList from '../../organisms/PostReportsList'

const PostReports = () => {
  return (
    <div className='ml-10 mt-12'>
        <DashboardTitle  page='reports'/>
        <PostReportsList />
    </div>
  )
}

export default PostReports