import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { useContactUserMutation } from '../../../services/UserService'
import { closeModal, openModal } from '../../../features/modal/modalSlice'
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { SectionUserIcon } from '../../../assets/icons/icons'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'
import SuggesterfyMessageField from '../../atoms/SuggesterfyMessageField'
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enum'
import asyncTimeout from '../../../utils/asyncTimeout'
import { closeSnackbar, openSnackbar } from '../../../features/snackbar/snackbarSlice'

const ContactUserModal:RFC<IdProps | undefined> = (id) => {
  const [ isProcessing, setIsProcessing ] = useState(false)
  const [ answerText, setAnswerText ] = useState('')
  const dispatch = useSuggesterfyDispatch()
  const [ contactUser, { isLoading } ] = useContactUserMutation()
  
  const sendMessage = async () => {
    if (!id) return
    const messageState = {
      userId: id.id,
      message: answerText
    }
    try {
      setIsProcessing(true)
      const [ data ] = await Promise.all([
        contactUser(messageState).unwrap(),
        asyncTimeout(1500)
      ])
      if (!data) return
      dispatch(closeModal())
      dispatch(openModal({ modalType: MODAL_TYPES.USER_CONTACT_CONFIRMED }))
    } catch (error) {
      dispatch(openSnackbar({
        type: SNACKBAR_TYPES.ERROR,
        message: ERROR_MESSAGES.CONTACT,
        timeout: 5000,
        btnText: SNACKBAR_BUTTON_TYPES.DISMISS,
        btnFunc: () =>  dispatch(closeSnackbar())
      }))
    } finally {
      setIsProcessing(false)
      setAnswerText('')
    }
  }

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SectionUserIcon />
          <p className='text-xl ml-2'>
          Contact User
          </p>
        </div>
        <SuggesterfyDashboardButton
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description className='w-[36rem]'>
        <div className='w-full pr-12'>
          <SuggesterfyMessageField 
            text={answerText} 
            isLoading={isLoading || isProcessing}
            changeText={text => setAnswerText(text)}
            onClick={sendMessage}
          />
        </div>
      </Dialog.Description>
    </>
  )
}

export default ContactUserModal