import { useEffect, useState } from "react"
import { RFC } from "../../types/reactTypes"
import { RenderUsersProps } from "../../types/propTypes"
import { useSuggesterfyDispatch, useSuggesterfySelector } from "../../app/hooks"
import { bannedUsersState, clearBannedUsers, setBannedUsers, setInitialBannedUsers } from "../../features/user/bannedUsersSlice"
import { selectBannedUser, userState } from "../../features/user/userSlice"
import { useLazyGetBannedUsersQuery } from "../../services/UserService"
import UserCardSkeleton from "./Skeletons/UserCardSkeleton"
import SuggesterfyCardError from "../atoms/SuggesterfyCardError"
import SuggesterfyCardLoading from "../atoms/SuggesterfyCardLoading"
import UserCard from "./UserCard"
import InfiniteScroll from "react-infinite-scroller"
import SuggesterfyLoading from "../atoms/SuggesterfyLoading"
import spaceConverter from "../../utils/spaceConverter"

const RenderBannedUsers:RFC<RenderUsersProps> = ({ searchText }) => {
    const take = 10
    const [ skip, setSkip ] = useState(0)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)

    const bannedUsers = useSuggesterfySelector(bannedUsersState)
    const dispatch = useSuggesterfyDispatch()
    
    const { id } = useSuggesterfySelector(userState)
    const [ getBannedUsers, { isLoading: bannedUserLoading, isError: bannedUserError, data:bannedUserData } ] = useLazyGetBannedUsersQuery()
    
    useEffect(() => {
        setSkip(0)
        loadMoreBannedUsers(10, 0)
    },[searchText])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreBannedUsers(take, skip)
    }

    const loadMoreBannedUsers = async (take: number, skip: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const bannedUsers = await getBannedUsers({ 
                take, 
                skip,
                search: spaceConverter(searchText),
            }).unwrap()
    
            if (!bannedUsers.length && skip === 0) dispatch(clearBannedUsers())
            if (!bannedUsers || !bannedUsers.length) {
                setHasMore(false)
            } else {
                if (skip === 0) {
                    dispatch(setInitialBannedUsers(bannedUsers))
                    setHasMore(true)
                    setSkip(10)
                } else if (bannedUsers.length < take) {
                    dispatch(setBannedUsers(bannedUsers))
                    setHasMore(true)
                } else {
                    dispatch(setBannedUsers(bannedUsers))
                    setHasMore(true)
                    setSkip(skip + 10)
                }
            }

            setTimeout(() => {
                setIsLoadMore(false)
            },1000)
        } catch (error) {
            console.log({error})
        }
    }

    const renderBannedUsers = () => {
        if (bannedUserLoading) return <SuggesterfyCardLoading />
        if (!bannedUserData)   return <UserCardSkeleton />
        if (bannedUserError)   return <SuggesterfyCardError />
        if (!bannedUsers.users.length) return (
            <div className="w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7">
                No Banned User
            </div>
        )
        
        return bannedUsers.users.map((user, index) => (
          <UserCard 
            key={index}
            user={user?.user ?? user}
            lastIndex={bannedUsers.users.length - 1 === index}
            isSelected={user.id === id}
            onSelectUser={() => dispatch(selectBannedUser(user))}
          />
        ))
    }
    
    return (
        <>
            <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                loader={<SuggesterfyLoading shape='circle' key={0}/>}
                threshold={150}
            >
                { renderBannedUsers() }
            </InfiniteScroll>
        </>
    )
}

export default RenderBannedUsers