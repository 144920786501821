import { RFC } from "../../types/reactTypes"
import { MessageCardProps } from "../../types/propTypes"
import moment from 'moment'

const UserMessageCard:RFC<MessageCardProps> = ({
    message,
    lastIndex,
    isSelected,
    onClick,
}) => {
    const { createdAt, message:content } = message
    const messagedDate = moment(createdAt).format('DD MMMM YYYY')

    return (
        <>
            <div
                className={isSelected
                    ? 'w-full my-1 py-4 px-7 hover:bg-SuggesterfyLightPink cursor-pointer transition-all duration-200'
                    : 'w-full my-1 py-4 px-7 hover:bg-SuggesterfyLightGrey cursor-pointer transition-all duration-200'
                }
                style={{background: isSelected ? '#FA503D1A' : undefined }}
                onClick={onClick}
            >
                <div className='flex w-full overflow-hidden'>
                    <p className='font-semibold'>
                        Message Sent
                    </p>
                    <p className='ml-4 text-SuggesterfyDark/70'>
                        {messagedDate}
                    </p>
                </div>
                <p className='py-2 text-base truncate'>
                    {content}
                </p>
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default UserMessageCard