import { useSuggesterfyDispatch, useSuggesterfySelector } from "../../../app/hooks"
import { closeModal, modalState, openModal } from "../../../features/modal/modalSlice"
import { MODAL_TYPES } from "../../../assets/data/enum"
import { Dialog } from "@headlessui/react"
import { PreviewPostIcon } from "../../../assets/icons/icons"
import SuggesterfyDashboardButton from "../../atoms/SuggesterfyDashboardButton"
import PostImageSlider from "../PostImageSlider"
import CommentCard from "../CommentCard"
import DOMPurify from 'dompurify'

const UserSuggestionModal = () => {
  const dispatch = useSuggesterfyDispatch()
  const suggestionState = useSuggesterfySelector(modalState).suggestion
  const hashtags = suggestionState?.hashtags?.map(tag => tag.title)
  const comments = suggestionState?.comments?.map(comment => comment)
  const deleteModalState = {
    modalType: MODAL_TYPES.USER_DELETE_POST,
    userId: suggestionState?.userId,
    postId: suggestionState?.id
  }

  const content = DOMPurify.sanitize(suggestionState?.content ?? '')

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <PreviewPostIcon />
          <p className='text-xl ml-2'>
            Preview Post
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <div className='w-full h-[20rem] overflow-x-hidden overflow-y-auto'>
        <p className='text-xl mt-2'>
        Original Post
        </p>
        <div className='flex items-center mt-1'>
          <p className='text-xl font-bold text-SuggesterfyRed'>Q</p>
          <div 
            className='pl-4 font-light tracking-wider'
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        <PostImageSlider images={suggestionState?.postImages ?? []}/>
        {comments && comments.length !== 0 && (
        <>
          <hr className='suggesterfy-border mt-3'/>
          <p className='text-xl mt-2'>
          Comments
          </p>
          <div className='w-full mb-4'>
            {comments.map((comment, index) => (
              <CommentCard 
                key={index}
                comment={comment}
                lastIndex={comments.length - 1 === index}
              />
            ))}
          </div>
          <hr className='suggesterfy-border'/>
        </>
        )}
        <div className='mt-4'>
          <SuggesterfyDashboardButton 
            text='Delete Post'
            btnStyle='outlined'
            onClick={() => dispatch(openModal(deleteModalState))}
          />
        </div>
      </div>
    </>
  )
}

export default UserSuggestionModal