const supportIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24">
          <g id="vuesax_outline_clipboard-text" data-name="vuesax/outline/clipboard-text" transform="translate(-108 -252)">
              <g id="clipboard-text">
              <path id="Vector" d="M7.75,1.5h-7A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h7A.755.755,0,0,1,8.5.75.755.755,0,0,1,7.75,1.5Z" transform="translate(115.25 263.45)" fill={color ?? '#333'}/>
              <path id="Vector-2" data-name="Vector" d="M5.13,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0H5.13a.755.755,0,0,1,.75.75A.755.755,0,0,1,5.13,1.5Z" transform="translate(115.25 267.45)" fill={color ?? '#333'}/>
              <path id="Vector-3" data-name="Vector" d="M6.75,5.5h-4C1.79,5.5,0,5.5,0,2.75S1.79,0,2.75,0h4C7.71,0,9.5,0,9.5,2.75,9.5,3.71,9.5,5.5,6.75,5.5Zm-4-4c-.99,0-1.25,0-1.25,1.25S1.76,4,2.75,4h4C8,4,8,3.74,8,2.75,8,1.5,7.74,1.5,6.75,1.5Z" transform="translate(115.25 253.25)" fill={color ?? '#333'}/>
              <path id="Vector-4" data-name="Vector" d="M12.75,19.481h-6C1.13,19.481,0,16.9,0,12.731v-6C0,2.171,1.65.221,5.71.011a.756.756,0,0,1,.79.71.742.742,0,0,1-.71.78c-2.84.16-4.29,1.01-4.29,5.23v6c0,3.7.73,5.25,5.25,5.25h6c4.52,0,5.25-1.55,5.25-5.25v-6c0-4.22-1.45-5.07-4.29-5.23A.757.757,0,0,1,13,.711.757.757,0,0,1,13.79,0c4.06.22,5.71,2.17,5.71,6.72v6C19.5,16.9,18.37,19.481,12.75,19.481Z" transform="translate(110.25 255.269)" fill={color ?? '#333'}/>
              <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 252)" fill="none" opacity="0"/>
              </g>
          </g>
      </svg>
    </div>
  )
}

export default supportIcon