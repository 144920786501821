import { RFC } from '../../types/reactTypes'
import { PostCardProps } from '../../types/propTypes'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'
import SuggesterfyAsyncImage from '../atoms/SuggesterfyAsyncImage'
import DOMPurify from 'dompurify'

const UserPostCard:RFC<PostCardProps> = ({
    post,
    lastIndex,
    isSelected,
    onClick,
}) => {
    const { postImages, content, user, type } = post
    const topImage = postImages[0]?.imageKey.toString()

    const postContent = DOMPurify.sanitize(content)
    
    return (
        <>
            <div
                className={isSelected
                    ? 'grid grid-rows-1 grid-cols-[80px_1fr] items-center h-[110px] my-1 py-4 px-7 hover:bg-SuggesterfyLightPink cursor-pointer transition-all duration-200'
                    : 'grid grid-rows-1 grid-cols-[80px_1fr] items-center h-[110px] my-1 py-4 px-7 hover:bg-SuggesterfyLightGrey cursor-pointer transition-all duration-200'
                }
                style={{background: isSelected ? '#FA503D1A' : undefined}}
                onClick={onClick}
            >
                <SuggesterfyAsyncImage 
                    imageKey={topImage}
                    size='medium'
                />
                <div className='flex flex-col justify-center ml-4 overflow-hidden'>
                    <div className='flex items-center pl-1 pt-1'>
                        <SuggesterfyAsyncAvatar 
                            imageKey={user?.avatar}
                            size='small'
                        />
                        <p className='text-lg font-semibold ml-4'>
                            {user?.firstName} {user?.lastName}
                        </p>
                        <p className='text-lg text-SuggesterfyDark opacity-50 ml-4'>
                            {type}
                        </p>
                    </div>
                    <div 
                        className='truncate font-semibold text-xl mt-1'
                        dangerouslySetInnerHTML={{ __html: postContent }}
                    ></div>
                </div>
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default UserPostCard