import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useDismissRequestMutation } from '../../../services/SupportService'
import { Dialog } from '@headlessui/react'
import SuggesterfyLoading from '../../atoms/SuggesterfyLoading'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { closeModal } from '../../../features/modal/modalSlice'
import { SupportIcon } from '../../../assets/icons/icons'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'
import { clearRequest } from '../../../features/support/requestSlice'
import { deleteRequest } from '../../../features/support/requestsSlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { useState } from 'react'
import { closeSnackbar, openSnackbar } from '../../../features/snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enum'

const DismissSupportModal:RFC<IdProps | undefined> = (id) => {
  const [ isProcessing, setIsProcessing ] = useState(false)
  const [ dissmissRequest, { isLoading, isError } ] = useDismissRequestMutation()
  const dispatch = useSuggesterfyDispatch()

  const handleDissmiss = async () => {
    if (!id) return
    try {
      setIsProcessing(true)
      const [ data ] = await Promise.all([
        dissmissRequest(id).unwrap(),
        asyncTimeout(1500)
      ])
      if (!data) return
      dispatch(clearRequest())
      dispatch(deleteRequest(id))
      dispatch(closeModal())
    } catch (error) {
      dispatch(openSnackbar({
        type: SNACKBAR_TYPES.ERROR,
        message: ERROR_MESSAGES.DISMISS,
        timeout: 5000,
        btnText: SNACKBAR_BUTTON_TYPES.RETRY,
        btnFunc: () => {
          dispatch(closeSnackbar())
          handleDissmiss()
        }
      }))
    } finally {
      setIsProcessing(false)
    }
  }

  const renderButtons = () => {
    if (isLoading || isProcessing) return <SuggesterfyLoading shape='circle' key={0}/>
    if (isError) return (
      <p className='mt-4 text-SuggesterfyRed'>
        Something went wrong. Please try again later.
      </p>
    )
    return(
      <div className='flex items-center'>
        <div className='mr-4'>
          <SuggesterfyDashboardButton 
            text='Dismiss Ticket'
            btnStyle='filled'
            onClick={handleDissmiss}
          />
        </div>
        <SuggesterfyDashboardButton 
          text='Keep Ticket'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </div>
    )
  }

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SupportIcon className='flex items-center'/>
          <p className='text-xl ml-2'>
          Dissmiss Support Ticket
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description>
        <span className='mb-4'>
          Are you sure you want to dismiss this ticket?
        </span>
        <p className='mb-4'>
          By dismissing this ticket it will be placed in a user account and you will leave this user with no answer.
        </p>
      </Dialog.Description>
      { renderButtons() }
    </>
  )
}

export default DismissSupportModal