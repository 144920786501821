import { useEffect, useState } from 'react'
import RequestCard from '../molecules/RequestCard'
import SectionTitle from '../molecules/SectionTitle'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../app/hooks'
import { selectRequest, requestState } from '../../features/support/requestSlice'
import { requestsState, setInitialRequests, setRequests } from '../../features/support/requestsSlice'
import { useLazyGetSupportRequestsQuery } from '../../services/SupportService'
import SupportCardSkeleton from '../molecules/Skeletons/SupportCardSkeleton'
import SuggesterfyLoading from '../atoms/SuggesterfyLoading'
import SuggesterfyCardLoading from '../atoms/SuggesterfyCardLoading'
import SuggesterfyCardError from '../atoms/SuggesterfyCardError'
import InfiniteScroll from 'react-infinite-scroller'
import { clearReports } from '../../features/report/reportsSlice'

const SupportRequestsList = () => {
  const take = 5
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const dispatch = useSuggesterfyDispatch()
  const allRequests = useSuggesterfySelector(requestsState).requests
  const { id } = useSuggesterfySelector(requestState)
  const [ getSupportRequests, { isLoading, isError, data } ] = useLazyGetSupportRequestsQuery()
  
  useEffect(() => {
    setSkip(0)
    loadMoreRequests(5, 0)
  }, [])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreRequests(take, skip)
  }

  const loadMoreRequests = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const { supportRequests } = await getSupportRequests({ take, skip }).unwrap()
      
      if (!supportRequests.length && skip === 0) dispatch(clearReports())
      if (!supportRequests || !supportRequests.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialRequests(supportRequests))
          setHasMore(true)
          setSkip(5)
        } else if (supportRequests.length < take) {
          dispatch(setRequests(supportRequests))
          setHasMore(false)
        } else {
          dispatch(setRequests(supportRequests))
          setHasMore(true)
          setSkip(skip + 5)
        }
      }
  
      setTimeout(() => {
        setIsLoadMore(false)
      }, 1000)
    } catch (error) {
      console.log({error})
    }
  }

  const renderRequestCards = () => {
    if (isLoading) return <SuggesterfyCardLoading />
    if (!data)     return <SupportCardSkeleton />
    if (isError)   return <SuggesterfyCardError />
    if (!allRequests.length) return (
      <div className="w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7">
        No Request
      </div>
    )
    
    return allRequests.map((request, index) => (
      <RequestCard 
        key={index}
        request={request}
        lastIndex={allRequests.length - 1 === index}
        isSelected={request.id === id}
        onClick={() => dispatch(selectRequest(request))}
      />
    ))
  }

  return (
    <div className='w-full h-[87%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden'>
      <SectionTitle page='support_request' title='Support Requests'/>
      <div className='w-full h-full overflow-y-auto overflow-x-hidden pb-[6em]'>
        <InfiniteScroll
          loadMore={fetchMoreData}
          hasMore={hasMore}
          useWindow={false}
          loader={<SuggesterfyLoading shape='circle' key={0}/>}
          threshold={150}
        >
          { renderRequestCards() }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default SupportRequestsList