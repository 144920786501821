import { useState } from 'react'
import SuggesterfyTextInput from '../atoms/SuggesterfyTextInput'
import SuggesterfyButton from '../atoms/SuggesterfyButton'
import { ForwardArrow } from '../../assets/icons/icons'
import { RFC } from '../../types/reactTypes'
import { FormikFormProps } from '../../types/propTypes'
import { AdminLogin } from '../../types/serviceTypes'

const LoginField:RFC<FormikFormProps<AdminLogin>> = ({ 
    values,
    touched,
    errors,
    isLoading,
    onChange,
    onSubmit,
}) => {
    const [ isVisible, setIsVisible ] = useState(false)

    return (
        <>
            <SuggesterfyTextInput 
                label='Email'
                name='email'
                type='text'
                value={values.email}
                required={true}
                onChange={onChange}
                touched={touched.email}
                error={errors.email ?? null}
            />
            <SuggesterfyTextInput 
                label='Password'
                name='password'
                type={isVisible ? 'text' : 'password'}
                value={values.password}
                required={true}
                onChange={onChange}
                touched={touched.password}
                error={errors.password ?? null}
                isVisible={isVisible}
                onIconPress={setIsVisible}
            />
            <div className='w-2/5 mt-4'>
                <SuggesterfyButton 
                    text='Continue'
                    rightIcon={<ForwardArrow />}
                    isLoading={isLoading}
                    disabled={false}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    )
}

export default LoginField