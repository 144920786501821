import { RFC } from '../../types/reactTypes'
import { UserProps } from '../../types/propTypes'
import UserInfo from './UserInfo'

const UserCard:RFC<UserProps> = ({ 
  user, 
  lastIndex,
  isSelected,
  onSelectUser
}) => {
  const { avatar, firstName, lastName } = user
  return (
    <>
      <div 
        className={isSelected
          ? 'flex items-center my-1 px-7 py-4 cursor-pointer transition-all duration-300 ease-in-out hover:bg-[rgba(250, 80, 61, 0.1)]'
          : 'flex items-center my-1 px-7 py-4 cursor-pointer transition-all duration-300 ease-in-out hover:bg-SuggesterfyLightGrey'
        }
        style={{background: isSelected ? 'rgba(250, 80, 61, 0.1)' : 'none'}}
        onClick={() => onSelectUser()}
      >
        <UserInfo 
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
        />
      </div>
      {!lastIndex && (
      <hr className='suggesterfy-border'/>
      )}
    </>
  )
}

export default UserCard