import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialReportsState } from '../../assets/data/initialStates'
import { ReturnReport } from '../../types/serviceTypes'
import { IdProps } from '../../types/propTypes'

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialReportsState,
    reducers: {
        clearReports: (state) => {
            state.reports = []
        },
        setInitialReports: (state, action:PayloadAction<ReturnReport[]>) => {
            state.reports = action.payload
        },
        setReports: (state, action:PayloadAction<ReturnReport[]>) => {
            const reportIds = state.reports.map(report => report.id)
            const filteredDuplicates = action.payload.filter(report => !reportIds.includes(report.id))
            filteredDuplicates.map(report => state.reports.push(report))
        },
        deleteReport: (state, action:PayloadAction<IdProps>) => {
            const filteredPosts = state.reports.filter(post => post.id !== action.payload.id)
            state.reports = filteredPosts
        }
    }
})

export const reportsState = (state: RootState) => state.reports
export const {
    clearReports,
    setInitialReports,
    setReports,
    deleteReport,
} = reportsSlice.actions
export default reportsSlice.reducer