import { useFormik } from 'formik'
import { SuggesterfyLogo } from '../../assets/images/Logo/Logo'
import LoginField from '../../components/organisms/LoginField'
import { initialAdminLoginState } from '../../assets/data/initialStates'
import { adminLoginSchema } from '../../utils/validationSchema'
import { useNavigate } from 'react-router-dom'
import asyncTimeout from '../../utils/asyncTimeout'
import { useAdminLoginMutation } from '../../services/AuthService'
import { useSuggesterfyDispatch } from '../../app/hooks'
import { closeSnackbar, openSnackbar } from '../../features/snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../assets/data/enum'

const Auth = () => {
  const navigate = useNavigate()
  const dispatch = useSuggesterfyDispatch()
  const [ adminLogin ] = useAdminLoginMutation()
  const { 
    values, errors, touched, isSubmitting, 
    handleSubmit, handleChange, setSubmitting, setFieldValue 
  } = useFormik({
    initialValues: initialAdminLoginState,
    validationSchema: adminLoginSchema,
    onSubmit: async (values) => {
      try {
        setSubmitting(true)
        const data = await adminLogin(values).unwrap()
        if (!data?.jwtToken) return
        localStorage.setItem('SuggesterfyAdminToken', data.jwtToken)
        await asyncTimeout(700)
        navigate('/support')
      } catch (error) {
        dispatch(openSnackbar({
          type: SNACKBAR_TYPES.ERROR,
          message: ERROR_MESSAGES.LOGIN,
          timeout: 10000,
          btnText: SNACKBAR_BUTTON_TYPES.RETRY,
          btnFunc: () => {
            setFieldValue(values.email, '')
            setFieldValue(values.password, '')
            dispatch(closeSnackbar())
          }
        }))
      } finally {
        setSubmitting(false)
      }
    }
  })

  return (
    <div className='w-[480px] h-screen m-auto flex flex-col justify-center'>
      <div className='w-full h-9'>
        <img src={SuggesterfyLogo} className='h-full' alt='suggesterfy admin'/>
      </div>
      <div className='w-full text-SuggesterfyRed font-bold text-4xl my-5'>
        Welcome To Suggesterfy.
      </div>
      <div className='w-full text-xl font-medium text-SuggesterfyDark mb-2'>
        Sign In:
      </div>
      <LoginField 
        values={values}
        touched={touched}
        errors={errors}
        isLoading={isSubmitting}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default Auth