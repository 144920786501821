import { RFC } from "../../types/reactTypes";
import { PillProps } from "../../types/propTypes";

const SuggesterfyPill: RFC<PillProps> = ({ title, isSelected, onSelect }) => {
  return (
    <div
      className={`whitespace-nowrap px-5 py-1 rounded-[30px]
            flex justify-center items-center cursor-pointer font-medium
            transition-all duration-200
            ${isSelected ? "hover:bg-[#FA503D]" : "hover:bg-[#FA503D1A]"}
            `}
      style={{
        background: isSelected ? "#FA503D" : "#EFEFEF",
        color: isSelected ? "#FFFFFF" : "#333333",
      }}
      onClick={() => onSelect()}
    >
      {title}
    </div>
  );
};

export default SuggesterfyPill;
