import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../app/hooks'
import { closeModal, modalState } from '../../features/modal/modalSlice'
import { MODAL_TYPES } from '../../assets/data/enum'
import DismissSupportModal from '../molecules/Modals/DismissSupportModal'
import SendMessageModal from '../molecules/Modals/SendMessageModal'
import DeleteReportModal from '../molecules/Modals/DeleteReportModal'
import KeepReportModal from '../molecules/Modals/KeepReportModal'
import ContactConfirmedModal from '../molecules/Modals/ContactConfirmedModal'
import BanUserModal from '../molecules/Modals/BanUserModal'
import UserMessageModal from '../molecules/Modals/UserMessageModal'
import UserQuestionModal from '../molecules/Modals/UserQuestionModal'
import UserSuggestionModal from '../molecules/Modals/UserSuggestionModal'
import UserSupportQuestionModal from '../molecules/Modals/UserSupportQuestionModal'
import UserReportedPostModal from '../molecules/Modals/UserReportedPostModal'
import { Dialog } from '@headlessui/react'
import ContactUserModal from '../molecules/Modals/ContactUserModal'
import UserDeletePostModal from '../molecules/Modals/UserDeletePostModal'

const SuggesterfyModal = () => {
  const state = useSuggesterfySelector(modalState)
  const dispatch = useSuggesterfyDispatch()

  const renderModal = () => {
    switch (state.modalType) {
      case MODAL_TYPES.DISMISS_SUPPORT:         return <DismissSupportModal id={state?.requestId ?? ''}/>
      case MODAL_TYPES.SEND_SUPPORT_MESSAGE:    return <SendMessageModal />
      case MODAL_TYPES.DELETE_REPORT:           return <DeleteReportModal id={state?.reportId ?? ''}/>
      case MODAL_TYPES.KEEP_REPORT:             return <KeepReportModal id={state?.reportId ?? ''}/>
      case MODAL_TYPES.CONTACT_USER:            return <ContactUserModal id={state?.userId ?? ''}/>
      case MODAL_TYPES.USER_CONTACT_CONFIRMED:  return <ContactConfirmedModal />
      case MODAL_TYPES.BAN_USER:                return <BanUserModal />
      case MODAL_TYPES.USER_MESSAGE:            return <UserMessageModal />
      case MODAL_TYPES.USER_QUESTION:           return <UserQuestionModal />
      case MODAL_TYPES.USER_SUGGESTION:         return <UserSuggestionModal />
      case MODAL_TYPES.USER_DELETE_POST:        return <UserDeletePostModal />
      case MODAL_TYPES.USER_SUPPORT_QUESTION:   return <UserSupportQuestionModal />
      case MODAL_TYPES.USER_REPORTED_POST:      return <UserReportedPostModal />
      default: return null
    }
  }

  return (
    <Dialog 
      open={state.isDisplayed} 
      onClose={() => dispatch(closeModal())}
      className='fixed inset-0 z-50 overflow-y-auto flex justify-center items-center'
    >
      <Dialog.Overlay className='fixed inset-0 bg-SuggesterfyDark/60'/>
      <Dialog.Panel className='relative bg-white rounded-[20px] w-[36rem] p-6'>
        { renderModal() }
      </Dialog.Panel>
    </Dialog>
  )
}

export default SuggesterfyModal