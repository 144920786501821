import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useGetUserReportsQuery } from '../../../services/UserService'
import { useState } from 'react'
import { ReturnReport } from '../../../types/serviceTypes'
import UserReportCard from '../../molecules/UserReportCard'
import SuggesterfyCardError from '../../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../../atoms/SuggesterfyCardLoading'
import UserReportSkeleton from '../../molecules/Skeletons/UserReportSkeleton'
import { useSuggesterfyDispatch } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enum'
import { openModal } from '../../../features/modal/modalSlice'

const ReportedPosts:RFC<IdProps> = ({ id }) => {
  const { isLoading, isError, data } = useGetUserReportsQuery({ take:100, skip:0, userId:id})
  const [ selectedReport, setSelectedReport ] = useState<ReturnReport>()
  const dispatch = useSuggesterfyDispatch()

  const handleClickCard = (report:ReturnReport) => {
    const modalState = {
      modalType: MODAL_TYPES.USER_REPORTED_POST,
      report
    }
    setSelectedReport(report)
    dispatch(openModal(modalState))
  }

  const renderReportedPosts = () => {
    if (!data)     return <UserReportSkeleton />
    if (isError)   return <SuggesterfyCardError />
    if (isLoading) return <SuggesterfyCardLoading />
    if (!data.length) return (
      <div className='w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7'>
        No Reported Report
      </div>
    )
    
    return data.map((report, index) => (
      <UserReportCard 
        key={index}
        report={report}
        lastIndex={data.length - 1 === index}
        isSelected={report.id === selectedReport?.id}
        onClick={() => handleClickCard(report)}
      />
    ))
  }

  return (
    <div className='w-full pb-[20em]'>
      { renderReportedPosts() }
    </div>
  )
}

export default ReportedPosts