const usersIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24">
            <g id="vuesax_outline_people" data-name="vuesax/outline/people" transform="translate(-618 -252)">
                <g id="people" transform="translate(-2)">
                <path id="Vector" d="M3.43,6.65H3.3a3.081,3.081,0,1,1,.14.01C3.44,6.65,3.44,6.65,3.43,6.65Zm-.1-5.16a1.831,1.831,0,0,0-.07,3.66.485.485,0,0,1,.18,0,1.831,1.831,0,0,0-.11-3.66Z" transform="translate(634.57 253.26)" fill={color ?? '#333'}/>
                <path id="Vector-2" data-name="Vector" d="M1.821,6.527a6.6,6.6,0,0,1-1.17-.1.751.751,0,1,1,.26-1.48,4.875,4.875,0,0,0,3.4-.6,1.372,1.372,0,0,0,.72-1.09,1.381,1.381,0,0,0-.72-1.08,4.893,4.893,0,0,0-3.43-.59.743.743,0,0,1-.87-.61.75.75,0,0,1,.61-.87,6.328,6.328,0,0,1,4.52.82,2.848,2.848,0,0,1,1.39,2.33A2.867,2.867,0,0,1,5.141,5.6,6.086,6.086,0,0,1,1.821,6.527Z" transform="translate(636.189 260.753)" fill={color ?? '#333'}/>
                <path id="Vector-3" data-name="Vector" d="M3.24,6.66H3.22a3.325,3.325,0,1,1,.23,0l-.21-.75.07.75Zm.1-1.5a.97.97,0,0,1,.17.01,1.86,1.86,0,1,0-.26,0A.2.2,0,0,1,3.34,5.16Z" transform="translate(622.73 253.25)" fill={color ?? '#333'}/>
                <path id="Vector-4" data-name="Vector" d="M4.71,6.527A6.086,6.086,0,0,1,1.39,5.6,2.864,2.864,0,0,1,0,3.257,2.862,2.862,0,0,1,1.39.927,6.328,6.328,0,0,1,5.91.107a.75.75,0,0,1,.61.87.743.743,0,0,1-.87.61,4.9,4.9,0,0,0-3.43.59,1.357,1.357,0,0,0-.72,1.08,1.4,1.4,0,0,0,.72,1.09,4.875,4.875,0,0,0,3.4.6.751.751,0,1,1,.26,1.48A6.6,6.6,0,0,1,4.71,6.527Z" transform="translate(621.25 260.753)" fill={color ?? '#333'}/>
                <path id="Vector-5" data-name="Vector" d="M3.43,6.65H3.3a3.081,3.081,0,1,1,.14.01C3.44,6.65,3.44,6.65,3.43,6.65Zm-.1-5.16a1.831,1.831,0,0,0-.07,3.66.485.485,0,0,1,.18,0,1.831,1.831,0,0,0-.11-3.66Z" transform="translate(628.57 260.73)" fill={color ?? '#333'}/>
                <path id="Vector-6" data-name="Vector" d="M4.72,6.54A6,6,0,0,1,1.39,5.6,2.848,2.848,0,0,1,0,3.27,2.854,2.854,0,0,1,1.39.93a6.427,6.427,0,0,1,6.66,0A2.848,2.848,0,0,1,9.44,3.26,2.854,2.854,0,0,1,8.05,5.6,6.08,6.08,0,0,1,4.72,6.54ZM2.22,2.19A1.372,1.372,0,0,0,1.5,3.28a1.381,1.381,0,0,0,.72,1.08,4.9,4.9,0,0,0,4.99,0,1.372,1.372,0,0,0,.72-1.09,1.381,1.381,0,0,0-.72-1.08A4.925,4.925,0,0,0,2.22,2.19Z" transform="translate(627.28 268.22)" fill={color ?? '#333'}/>
                <path id="Vector-7" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default usersIcon