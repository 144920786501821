import { RFC } from '../../types/reactTypes'
import { DashboardProps } from '../../types/propTypes'
import { SuggesterfyLogo } from '../../assets/images/Logo/Logo'
import { LogoutIcon, SupportIcon, UsersIcon } from '../../assets/icons/icons'
import { useNavigate } from 'react-router-dom'

const Sidebar:RFC<DashboardProps> = ({ page }) => {
  const isNoticeClicked = page === 'support' || page === 'reports'
  const isUserClicked = page === 'users'
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('SuggesterfyAdminToken')
    navigate('/login')
  }

  return (
    <div
      className='flex justify-between flex-col w-[15%] bg-SuggesterfyLightPink h-screen overflow-hidden min-w-[13em]'
    >
      <div>
        <div className='w-full h-6 pl-8 my-10'>
          <img src={SuggesterfyLogo} className='h-full' alt='suggesterfy admin'/>
        </div>
        <div className='pt-5'>
          <div>
            <div className='w-full flex items-center cursor-pointer'>
              <SupportIcon 
                width={28} 
                height={28} 
                color={isNoticeClicked ? '#FA503D' : '#333'}
                className='ml-[1.2em] mr-[0.5em]'
              />
              <div 
                className='font-semibold text-[1.1em]'
                style={{color: isNoticeClicked ? '#FA503D' : '#333333'}}
                onClick={() => navigate('/support')}
              >
                <p className='min-w-fit sidebar-text sidebar-hover-bg'>
                  Notice Board
                </p>
              </div>
            </div>
            <div className='ml-[2em] my-3 py-3 border-l-[1.5px] border-SuggesterfyDark/50'>
              <div className='pl-[1.2em] pb-3 text-[1.1em] cursor-pointer'>
                <p 
                  className='max-w-fit sidebar-text sidebar-hover-bg hover:hover-color'
                  style={{color: page === 'support' ? '#FA503D' : 'rgba(51, 51, 51, 0.5)'}}
                  onClick={() => navigate('/support')}
                >
                  Support
                </p>
              </div>
              <div className='pl-[1.2em] pt-3 text-[1.1em] cursor-pointer '>
                <p 
                  className='max-w-fit sidebar-text sidebar-hover-bg hover:hover-color'
                  style={{color: page === 'reports' ? '#FA503D' : 'rgba(51, 51, 51, 0.5)'}}
                  onClick={() => navigate('/reports')}
                >
                  Post Reports
                </p>
              </div>
            </div>
          </div>
          <div 
            className='w-full flex items-center cursor-pointer'
            onClick={() => navigate('/users')}
          >
            <UsersIcon 
              width={28} 
              height={28} 
              color={isUserClicked ? '#FA503D' : '#333333'}
              className='ml-[1.2em] mr-[0.5em]'
            />
            <div 
              className='font-semibold text-lg'
              style={{color: isUserClicked ? '#FA503D' : '#333333'}}
            >
              <p 
                className='max-w-fit sidebar-text sidebar-hover-bg'
                onClick={() => navigate('/reports')}
              >
                Users
              </p>
            </div>
          </div>
        </div>
      </div>
      <div 
        className='w-full flex items-center mb-8 cursor-pointer'
        onClick={logout}  
      >
        <LogoutIcon 
          width={28} 
          height={28}
          className='mx-[1.2em] mr-5'
        />
        <p className='text-lg font-semibold'>
        Logout
        </p>
      </div>
    </div>
  )
}

export default Sidebar