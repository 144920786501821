const messageButton = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24">
            <g id="vuesax_outline_note-remove" data-name="vuesax/outline/note-remove" transform="translate(-236 -316)">
                <g id="note-remove">
                <path id="Vector" d="M5.75,1.5h-5A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h5A.755.755,0,0,1,6.5.75.755.755,0,0,1,5.75,1.5Z" transform="translate(242.25 329.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-2" data-name="Vector" d="M4.5,5.247a.742.742,0,0,1-.53-.22L.218,1.278A.75.75,0,0,1,1.277.218l3.75,3.75a.754.754,0,0,1,0,1.06A.786.786,0,0,1,4.5,5.247Z" transform="translate(238.503 317.462)" fill={color ?? '#292d32'}/>
                <path id="Vector-3" data-name="Vector" d="M.747,5.247a.742.742,0,0,1-.53-.22.754.754,0,0,1,0-1.06L3.967.218a.75.75,0,1,1,1.06,1.06l-3.75,3.75A.725.725,0,0,1,.747,5.247Z" transform="translate(238.462 317.503)" fill={color ?? '#292d32'}/>
                <path id="Vector-4" data-name="Vector" d="M8.75,1.5h-8A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h8A.755.755,0,0,1,9.5.75.755.755,0,0,1,8.75,1.5Z" transform="translate(242.25 325.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-5" data-name="Vector" d="M11.75,15.5a.755.755,0,0,1-.75-.75V6.74C11,2.51,9.56,1.65,6.71,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h6c4.1.22,5.75,2.17,5.75,6.74v8.01A.755.755,0,0,1,11.75,15.5Z" transform="translate(245.25 317.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-6" data-name="Vector" d="M12.75,14.49h-6C1.13,14.49,0,11.9,0,7.72V.75A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75V7.72c0,3.72.73,5.27,5.25,5.27h6a.75.75,0,0,1,0,1.5Z" transform="translate(238.25 324.26)" fill={color ?? '#292d32'}/>
                <path id="Vector-7" data-name="Vector" d="M.75,7.5A.755.755,0,0,1,0,6.75v-3A3.381,3.381,0,0,1,3.75,0h3a.741.741,0,0,1,.69.46.776.776,0,0,1-.16.82l-6,6A.767.767,0,0,1,.75,7.5Zm3-6c-1.58,0-2.25.67-2.25,2.25V4.94L4.94,1.5Z" transform="translate(250.25 331.25)" fill={color ?? '#292d32'}/>
                <path id="Vector-8" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(236 316)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default messageButton