import { useEffect, useState } from 'react'
import { RFC } from '../../types/reactTypes'
import { ImageProps } from '../../types/propTypes'
import { useDownloadImageMutation } from '../../services/Files'
import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyAsyncImage:RFC<ImageProps> = ({ imageKey, size }) => {
    const [ imageUrl, setImageUrl ] = useState(imageKey ? '' : undefined)
    const [ isImageError, setIsImageError ] = useState(false)
    const [ getDownloadImage, { isLoading, isError } ] = useDownloadImageMutation()
    
    const getIconSize = () => {
        switch (size) {
            case 'small':   return 20
            case 'medium':  return 24
            case 'large':   return 28
            default:        return 0
        }
    }

    useEffect(() => {
        getPresignedUrl()
    },[])

    const getPresignedUrl = async () => {
        if (!imageKey) return
        try {
            const presignedUrl = await getDownloadImage(imageKey).unwrap()
            setImageUrl(presignedUrl)
        } catch (error) {
            console.log({error})
        }
    }

    const renderUserMainImage = () => {
        if (imageUrl === undefined) return (
            <img 
                className={`w-full h-full rounded-[5px] shadow-lg hover:shadow-xl`}
                src={'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                alt={'error'}
            />
        )
        if (isLoading) return (
            <div className={`flex justify-center w-full h-full} rounded-[5px] shadow-lg hover:shadow-xl`}>
                <SuggesterfyLoading shape='circle' />
            </div>
        )
        if (isError || isImageError) return (
            <img 
                className={`w-full h-full rounded-[5px] shadow-lg hover:shadow-xl`}
                src={'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                alt={'error'}
            />
        )
        return (
            <img 
                className={`w-full h-full rounded-[5px] shadow-lg hover:shadow-xl object-cover transition-all duration-300`}
                src={imageUrl}
                alt={'error'}
                onError={() => setIsImageError(true)}
            />
        )
    }

    return (
        <>
        { renderUserMainImage() }
        </>
    )
}

export default SuggesterfyAsyncImage