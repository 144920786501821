import DashboardTitle from '../../molecules/DashboardTitle'
import SupportRequestsList from '../../organisms/SupportRequestsList'

const SupportRequests = () => {
  
  return (
    <div className='ml-10 mt-12'>
      <DashboardTitle page='support'/>
      <SupportRequestsList />
    </div>
  )
}

export default SupportRequests