import { RFC } from '../../types/reactTypes'
import { UserProfileProps } from '../../types/propTypes'
import SuggesterfyDashboardButton from '../atoms/SuggesterfyDashboardButton'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const UserProfile:RFC<UserProfileProps> = ({ 
    avatar,
    firstName,
    lastName,
    btnFunc,
}) => {
    
    return (
        <div className='flex items-center justify-between mx-7 my-6 px-4 py-3 rounded-xl bg-SuggesterfyLightGrey'>
            <div className='flex items-center'>
                <SuggesterfyAsyncAvatar imageKey={avatar} size='medium'/>
                <p className='text-xl font-semibold ml-4'>
                    {firstName}
                </p>
                <p className='text-xl font-semibold ml-2'>
                    {lastName}
                </p>
            </div>
            {btnFunc && (
                <SuggesterfyDashboardButton text='View Profile' btnStyle='filled' onClick={btnFunc}/>
            )}
        </div>
    )
}

export default UserProfile