import { RFC } from '../../types/reactTypes'
import { RequestCardProps } from '../../types/propTypes'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const UserSupportCard:RFC<RequestCardProps> = ({
    request,
    lastIndex,
    isSelected,
    onClick,
}) => {
    const { title, body, user } = request

    return (
        <>
            <div
                className={isSelected
                    ? 'my-1 py-4 px-7 hover:bg-SuggesterfyLightPink cursor-pointer transition-all duration-200'
                    : 'my-1 py-4 px-7 hover:bg-SuggesterfyLightGrey cursor-pointer transition-all duration-200'
                }
                style={{background: isSelected ? '#FA503D1A' : undefined}}
                onClick={onClick}
            >
                <div className='w-full overflow-hidden'>
                    <p className='truncate font-semibold text-2xl mt-1'>
                        {title}
                    </p>
                    <p className='truncate font-normal text-lg mt-1'>
                        {body}
                    </p>
                </div>
                <div className='flex flex-col justify-center overflow-hidden'>
                    <div className='flex items-center pl-1 py-1'>
                        <SuggesterfyAsyncAvatar 
                            imageKey={user?.avatar}
                            size='small'
                        />
                        <p className='text-lg font-semibold ml-4'>
                            {user?.firstName} {user?.lastName}
                        </p>
                    </div>
                </div>
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default UserSupportCard