import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { closeModal, modalState, openModal } from '../../../features/modal/modalSlice'
import { PreviewPostIcon } from '../../../assets/icons/icons'
import { Dialog } from '@headlessui/react'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'
import PostImageSlider from '../PostImageSlider'
import CommentCard from '../CommentCard'
import { MODAL_TYPES } from '../../../assets/data/enum'
import DOMPurify from 'dompurify'

const UserQuestionModal = () => {
  const dispatch = useSuggesterfyDispatch()
  const questionState = useSuggesterfySelector(modalState).question
  const comments = questionState?.comments?.map(comment => comment)
  const deleteReportedPostState = {
    modalType: MODAL_TYPES.DELETE_REPORT,
    reportId: questionState?.id
  }
  const deletePostState = {
    modalType: MODAL_TYPES.USER_DELETE_POST,
    userId: questionState?.userId,
    postId: questionState?.id,
  }

  const handleDeletePost = () => {
    if (questionState?.reports?.length) dispatch(openModal(deleteReportedPostState))
    else dispatch(openModal(deletePostState))
  }

  const content = DOMPurify.sanitize(questionState?.content ?? '')

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <PreviewPostIcon />
          <p className='text-xl ml-2'>
          Preview Post
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <div className='w-full h-[20rem] overflow-x-hidden overflow-y-auto'>
        <p className='text-xl mt-2'>
        Original Post
        </p>
        <div className='flex items-center mt-1'>
          <p className='text-xl font-bold text-SuggesterfyRed'>Q</p>
          <div 
            className='pl-4 font-light tracking-wider'
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        <PostImageSlider images={questionState?.postImages ?? []}/>
        {comments && comments.length !== 0 && (
          <>
          <hr className='suggesterfy-border mt-3'/>
          <p className='text-xl mt-2'>
          Comments
          </p>
          <div className='w-full mb-4'>
            {comments.map((comment, index) => (
              <CommentCard 
                key={index}
                comment={comment}
                lastIndex={comments.length - 1 === index}
              />
              ))}
          </div>
          <hr className='suggesterfy-border'/>
        </>
        )}
        <div className='mt-4'>
        <SuggesterfyDashboardButton 
          text='Delete Post'
          btnStyle='outlined'
          onClick={handleDeletePost}
        />
        </div>
      </div>
    </>
  )
}

export default UserQuestionModal