import { RFC } from '../../types/reactTypes'
import { ButtonProp } from '../../types/propTypes'
import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyButton:RFC<ButtonProp> = ({ 
    text,
    leftIcon,
    rightIcon,
    disabled,
    isLoading,
    onSubmit,
}) => {
    return (
        <>
            {isLoading
            ?
            <div
                className='h-[54px] bg-SuggesterfyPink flex justify-center items-center 
                rounded-[12px] w-full text-xl px-4 py-3'
            >
                <SuggesterfyLoading type='button' shape='dots'/>
            </div>
            :
            <button
                type='submit'
                data-mdb-ripple='true'
                data-mdb-ripple-color='light'
                className='bg-SuggesterfyRed flex justify-center items-center  
                text-SuggesterfyWhite rounded-[12px] w-full text-xl px-4 py-3
                disabled:bg-SuggesterfyPink disabled:cursor-not-allowed hover:bg-[#E13724]
                transition-all duration-300'
                disabled={disabled}
                onClick={onSubmit}
            >
                { leftIcon }
                { text }
                { rightIcon }
            </button>
            }
        </>
    )
}

export default SuggesterfyButton