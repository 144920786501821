import { useState } from 'react'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { clearRequest, requestState } from '../../../features/support/requestSlice'
import SectionTitle from '../../molecules/SectionTitle'
import UserProfile from '../../molecules/UserProfile'
import SuggesterfyMessageField from '../../atoms/SuggesterfyMessageField'
import { openModal } from '../../../features/modal/modalSlice'
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../../assets/data/enum'
import { useNavigate } from 'react-router-dom'
import { selectUserProfile } from '../../../features/user/userSlice'
import { useResponseRequestMutation } from '../../../services/SupportService'
import { deleteRequest } from '../../../features/support/requestsSlice'
import { closeSnackbar, openSnackbar } from '../../../features/snackbar/snackbarSlice'

const SupportTicket = () => {
  const { id, title, body, user } = useSuggesterfySelector(requestState)
  const [ answerText, setAnswerText ] = useState('')
  const navigate = useNavigate()
  const dispatch = useSuggesterfyDispatch()
  const [ responseRequest, { isLoading } ] = useResponseRequestMutation()
  const modalState = {
    modalType: MODAL_TYPES.DISMISS_SUPPORT,
    requestId: id
  }
  const messageState = {
    supportId: id,
    message: answerText,
  }

  const visitUserProfile = () => {
    navigate('/users')
    dispatch(selectUserProfile(user))
  }

  const sendRequestMessage = async () => {
    try {
      const data = await responseRequest(messageState).unwrap()
      if (!data) return
      dispatch(clearRequest())
      dispatch(deleteRequest({id}))
      dispatch(openModal({ modalType: MODAL_TYPES.SEND_SUPPORT_MESSAGE }))
    } catch (error) {
      dispatch(openSnackbar({
        type: SNACKBAR_TYPES.ERROR,
        message: ERROR_MESSAGES.MESSAGE,
        timeout: 5000,
        btnText: SNACKBAR_BUTTON_TYPES.RETRY,
        btnFunc: () => {
          dispatch(closeSnackbar())
          sendRequestMessage()
        }
      }))
    } finally {
      setAnswerText('')
    }
  }

  return (
    <div 
      className='w-[95%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden
      mx-4 mt-[136px] mb-5'
    >
      {id
      ? <>
          <SectionTitle 
            page='support_ticket' 
            title='Support Ticket' 
            btnTitle='Dismiss' 
            btnFunc={() => dispatch(openModal(modalState))} 
          />
          <div className='h-full overflow-auto pb-[90px]'>
            <UserProfile 
              avatar={user?.avatar}
              firstName={user?.firstName}
              lastName={user?.lastName}
              btnFunc={visitUserProfile}
            />
            <div className='mx-7 my-6 max-h-[300px] overflow-auto pb-4'>
              <p className='text-3xl font-semibold mb-3'>
                {title}
              </p>
              <p className='font-light'>
                {body}
              </p>
            </div>
            <hr className='suggesterfy-border'/>
            <div className='mx-7 my-6'>
              <SuggesterfyMessageField 
                text={answerText} 
                isLoading={isLoading}
                changeText={text => setAnswerText(text)}
                onClick={sendRequestMessage}
              />
            </div>
          </div>
        </>
      : <div className='w-full h-full flex justify-center items-center text-SuggesterfyDark opacity-70'>
        Select a support request to see details
        </div>
      }
    </div>
  )
}

export default SupportTicket