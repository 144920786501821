import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialPostsState } from '../../assets/data/initialStates'
import { Post } from '../../types/stateTypes'
import { PostIdProps } from '../../types/propTypes'

export const postsSlice = createSlice({
    name: 'posts',
    initialState: initialPostsState,
    reducers: {
        clearPosts: (state) => {
            state.posts = []
        },
        setInitialPosts: (state, action: PayloadAction<Post[]>) => {
            state.posts = action.payload
        },
        setQuestions: (state, action:PayloadAction<Post[]>) => {
            const questionIds = state.posts.map(question => question.id)
            const filteredDuplicates = action.payload.filter(question => !questionIds.includes(question.id))
            filteredDuplicates.map(question => state.posts.push(question))
        },
        setSuggestions: (state, action:PayloadAction<Post[]>) => {
            const suggestionIds = state.posts.map(suggestion => suggestion.id)
            const filteredDuplicates = action.payload.filter(suggestion => !suggestionIds.includes(suggestion.id))
            filteredDuplicates.map(question => state.posts.push(question))
        },
        deleteUserPost: (state, action:PayloadAction<PostIdProps>) => {
            const filteredPosts = state.posts.filter(post => post.id !== action.payload.postId)
            state.posts = filteredPosts
        }
    }
})

export const postsState = (state: RootState) => state.posts
export const {
    clearPosts,
    setInitialPosts,
    setQuestions,
    setSuggestions,
    deleteUserPost,
} = postsSlice.actions
export default postsSlice.reducer
