import React from 'react';
import DashboardTitle from '../../molecules/DashboardTitle';
import UserOverviewPanel from './UserOverviewPanel';
import ExportUsersWidget from '../../molecules/ExportUsers';

const UserOverview = () => {
	return (
		<div className="ml-10 mt-12">
			<ExportUsersWidget />
			<UserOverviewPanel />
		</div>
	);
};

export default UserOverview;
