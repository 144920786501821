const eyeClose = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? 24}
            height={height ?? 24}
            viewBox="0 0 20 20" 
            fill="currentColor"
        >
            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
        </svg>
      </div>
    )
  }
  
  export default eyeClose