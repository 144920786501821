const spaceConverter = (text:string) => {
    const isContainsWhitespace = /\s/.test(text)
    if (isContainsWhitespace) {
        const dashes = text.replace(/\s+/g, '-').toLowerCase()
        return dashes
    } else {
        return text
    }
}

export default spaceConverter