import { useEffect, useState } from 'react'
import { RFC } from '../../../types/reactTypes'
import { IdProps } from '../../../types/propTypes'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { clearPosts, postsState, setInitialPosts, setSuggestions } from '../../../features/user/postsSlice'
import { Post } from '../../../types/stateTypes'
import { useLazyGetUserSuggestionsQuery } from '../../../services/UserService'
import UserPostsSkeleton from '../../molecules/Skeletons/UserPostsSkeleton'
import SuggesterfyCardError from '../../atoms/SuggesterfyCardError'
import SuggesterfyCardLoading from '../../atoms/SuggesterfyCardLoading'
import UserPostCard from '../../molecules/UserPostCard'
import InfiniteScroll from 'react-infinite-scroller'
import SuggesterfyLoading from '../../atoms/SuggesterfyLoading'
import { MODAL_TYPES } from '../../../assets/data/enum'
import { openModal } from '../../../features/modal/modalSlice'

const Suggestions:RFC<IdProps> = ({ id }) => {
  const take = 5
  const [ skip, setSkip ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ selectedSuggestion, setSelectedSuggestion ] = useState<Post>()

  const allSuggestions = useSuggesterfySelector(postsState)
  const dispatch = useSuggesterfyDispatch()

  const [ getUserPost, { isLoading, isError, data } ] = useLazyGetUserSuggestionsQuery()

  useEffect(() => {
    setSkip(0)
    loadMorePosts(5, 0)
  },[id])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMorePosts(take, skip)
  }

  const loadMorePosts = async (take: number, skip: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const suggestions = await getUserPost({ 
        take, 
        skip, 
        userId:id 
      }).unwrap()
  
      if (!suggestions.length && skip === 0) dispatch(clearPosts())
      if (!suggestions || !suggestions.length) {
        setHasMore(false)
      } else {
        if (skip === 0) {
          dispatch(setInitialPosts(suggestions))
          setHasMore(true)
          setSkip(5)
        } else if (suggestions.length < take) {
          dispatch(setSuggestions(suggestions))
          setHasMore(false)
        } else {
          dispatch(setSuggestions(suggestions))
          setHasMore(true)
          setSkip(skip + 5)
        }
      }
  
      setTimeout(() => {
        setIsLoadMore(false)
      },1000)
    } catch (error) {
      console.log({error})
    }
  }

  const handleClickCard = (suggestion:Post) => {
    const modalState = {
      modalType: MODAL_TYPES.USER_SUGGESTION,
      suggestion
    }
    setSelectedSuggestion(suggestion)
    dispatch(openModal(modalState))
  }

  const renderQuestions = () => {
    if (!data)     return <UserPostsSkeleton />
    if (isError)   return <SuggesterfyCardError />
    if (isLoading) return <SuggesterfyCardLoading />
    if (!allSuggestions.posts.length) return (
      <div className='w-full h-[5em] text-SuggesterfyDark text-lg flex justify-center items-center px-7'>
        No Suggestion
      </div>
    )
    
    return allSuggestions.posts.map((post, index) => (
      <UserPostCard 
        key={index}
        post={post}
        lastIndex={allSuggestions.posts.length - 1 === index}
        isSelected={post.id === selectedSuggestion?.id}
        onClick={() => handleClickCard(post)}
      />
    ))
  }

  return (
    <div className='w-full pb-[20em]'>
      <InfiniteScroll
        loadMore={fetchMoreData}
        hasMore={hasMore}
        useWindow={false}
        loader={<SuggesterfyLoading shape='circle' key={0}/>}
        threshold={150}
      >
        { renderQuestions() }
      </InfiniteScroll>
    </div>
  )
}

export default Suggestions