const SupportCardSkeleton = () => {
    return (
        <>
            {Array(7).fill('').map((_,i) => (
                <div
                    role='status'
                    className='w-full space-y-8 animate-pulse'
                    key={i}
                >
                <div className='flex items-center mt-5 px-5 space-x-3'>
                    <svg 
                        className='w-14 h-14 text-[#CED0D6] dark:text-[#CED0D6]' 
                        aria-hidden="true" 
                        fill="currentColor" 
                        viewBox="0 0 20 20" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path 
                            fillRule="evenodd" 
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" 
                            clipRule="evenodd"
                        >
                        </path>
                    </svg>
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-[#CED0D6] w-32 mb-2"></div>
                        <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-[#CED0D6]"></div>
                    </div>
                </div>
            </div>
            ))}
        </>
    )
}

export default SupportCardSkeleton