import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import modalReducer from '../features/modal/modalSlice';
import loadingReducer from '../features/modal/banUserSlice';
import adminReducer from '../features/admin/adminSlice';
import requestReducer from '../features/support/requestSlice';
import requestsReducer from '../features/support/requestsSlice';
import reportReducer from '../features/report/reportSlice';
import reportsReducer from '../features/report/reportsSlice';
import userReducer from '../features/user/userSlice';
import usersReducer from '../features/user/usersSlice';
import bannedUsersReducer from '../features/user/bannedUsersSlice';
import postsReducer from '../features/user/postsSlice';

export const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		snackbar: snackbarReducer,
		modal: modalReducer,
		loading: loadingReducer,
		admin: adminReducer,
		request: requestReducer,
		requests: requestsReducer,
		report: reportReducer,
		reports: reportsReducer,
		user: userReducer,
		users: usersReducer,
		bannedUsers: bannedUsersReducer,
		posts: postsReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
	devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
