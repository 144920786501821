import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialUsersState } from '../../assets/data/initialStates'
import { User } from '../../types/stateTypes'

export const usersSlice = createSlice({
    name: 'users',
    initialState: initialUsersState,
    reducers: {
        clearUsers: (state) => {
            state.users = []
        },
        setInitialUsers: (state, action:PayloadAction<User[]>) => {
            state.users = action.payload
        },
        setUsers: (state, action:PayloadAction<User[]>) => {
            const userIds = state.users.map(user => user.id)
            const filteredDuplicates = action.payload.filter(user => !userIds.includes(user.id))
            filteredDuplicates.map(user => state.users.push(user))
        },
    }
})

export const usersState = (state: RootState) => state.users
export const {
    clearUsers,
    setInitialUsers,
    setUsers,
} = usersSlice.actions
export default usersSlice.reducer