const forwardArrow = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '30'} height={height ?? '30'} viewBox="0 0 30 30">
            <g id="Back_Arrow" data-name="Back Arrow" transform="translate(30 30) rotate(180)">
                <path id="Path_758" data-name="Path 758" d="M26.724,17.984H14.031l5.476-5.476a1.2,1.2,0,0,0-1.7-1.7l-6.767,6.767a2.271,2.271,0,0,0,0,3.212l6.768,6.767a1.2,1.2,0,0,0,1.7-1.7l-5.476-5.476H26.724a1.2,1.2,0,0,0,0-2.4Z" transform="translate(-4.151 -4.184)" fill="#fff"/>
                <rect id="Rectangle_160" data-name="Rectangle 160" width="30" height="30" fill="none"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default forwardArrow