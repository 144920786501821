import { api } from '../app/api'
import { AdminLogin, ReturnAdmin } from '../types/serviceTypes'

export const registerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        adminLogin: builder.mutation<ReturnAdmin, AdminLogin>({
            query: ({ email, password }) => ({
                url: '/admin/login',
                method: 'POST',
                body: {
                    email,
                    password
                }
            }),
        }),
    }) 
})

export const { useAdminLoginMutation } = registerApi