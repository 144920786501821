const messageButton = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24">
            <g id="export" transform="translate(-492 -444)">
                <path id="Vector" d="M5.12,3.31a.748.748,0,0,1-.53-.22L2.56,1.061.53,3.09a.75.75,0,0,1-1.061,0,.75.75,0,0,1,0-1.061L2.03-.53a.75.75,0,0,1,1.061,0L5.65,2.03a.75.75,0,0,1-.53,1.28Z" transform="translate(501.44 447.94)" fill={color ?? '#FFF'}/>
                <path id="Vector-2" data-name="Vector" d="M0,10.92a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V10.17A.75.75,0,0,1,0,10.92Z" transform="translate(504 448.01)" fill={color ?? '#FFF'}/>
                <path id="Vector-3" data-name="Vector" d="M8,8.75A8.593,8.593,0,0,1,1.581,6.174,8.879,8.879,0,0,1-.75,0,.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0,7.388,7.388,0,0,0,2.669,5.141,7.131,7.131,0,0,0,8,7.25a7.131,7.131,0,0,0,5.331-2.109A7.388,7.388,0,0,0,15.25,0,.75.75,0,0,1,16-.75a.75.75,0,0,1,.75.75,8.879,8.879,0,0,1-2.331,6.174A8.593,8.593,0,0,1,8,8.75Z" transform="translate(496 456)" fill={color ?? '#FFF'}/>
                <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(516 468) rotate(180)" fill="none" opacity="0"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default messageButton