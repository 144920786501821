import { RFC } from "../../types/reactTypes";
import { SectionTitleProps } from "../../types/propTypes";
import {
  ReportedPostIcon,
  ReportIcon,
  SearchUserIcon,
  SectionUserIcon,
  SupportIcon,
  UserIcon,
} from "../../assets/icons/icons";
import SuggesterfyDashboardButton from "../atoms/SuggesterfyDashboardButton";

const SectionTitle: RFC<SectionTitleProps> = ({
  page,
  title,
  btnTitle,
  btnFunc,
  keepPost,
  userCount,
}) => {
  const renderIcon = () => {
    switch (page) {
      case "support_request":
        return <SectionUserIcon className="flex items-center" />;
      case "support_ticket":
        return <SupportIcon className="flex items-center" />;
      case "reports":
        return <ReportIcon className="flex items-center" />;
      case "reported_post":
        return <ReportedPostIcon className="flex items-center" />;
      case "user_list":
        return <SearchUserIcon className="flex items-center" />;
      case "user_overview":
        return <UserIcon className="flex items-center" />;
      default:
        return;
    }
  };

  return (
    <>
      <div className="relative flex items-center justify-between px-7 py-6 max-h-[76px]">
        <div
          className={`flex justify-between items-center ${
            page === "user_list" && "w-full"
          }`}
        >
          <div className="flex items-center">
            {renderIcon()}
            <p className="text-xl ml-2">{title}</p>
          </div>
          {page === "user_list" && userCount && (
            <div className="flex flex-col justify-center">
              <p className="text-xs">Total Users</p>
              <p className="text-center text-xl">{userCount}</p>
            </div>
          )}
        </div>
        <div className="flex">
          {btnTitle && btnFunc && (
            <div className="mr-4">
              <SuggesterfyDashboardButton
                text={btnTitle}
                btnStyle="outlined"
                onClick={btnFunc}
              />
            </div>
          )}
          {page === "reported_post" && keepPost && (
            <div className="mr-4">
              <SuggesterfyDashboardButton
                text={"Keep Post"}
                btnStyle="filled"
                onClick={keepPost}
              />
            </div>
          )}
        </div>
      </div>
      <hr className="suggesterfy-border" />
    </>
  );
};

export default SectionTitle;
