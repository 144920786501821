import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialRequestsState } from '../../assets/data/initialStates'
import { ReturnSupport } from '../../types/serviceTypes'
import { IdProps } from '../../types/propTypes'

export const requestsSlice = createSlice({
    name: 'requests',
    initialState: initialRequestsState,
    reducers: {
        clearRequests: (state) => {
            state.requests = []
        },
        setInitialRequests: (state, action:PayloadAction<ReturnSupport[]>) => {
            state.requests = action.payload
        },
        setRequests: (state, action:PayloadAction<ReturnSupport[]>) => {
            const requestIds = state.requests.map(request => request.id)
            const nonRepliedRequests = action.payload.filter(request => request.supportReply.length === 0)
            const filteredDuplicates = nonRepliedRequests.filter(request => !requestIds.includes(request.id))
            filteredDuplicates.map(request => state.requests.push(request))
        },
        deleteRequest: (state, action:PayloadAction<IdProps>) => {
            const filteredRequests = state.requests.filter(request => request.id !== action.payload.id)
            state.requests = filteredRequests
        }
    }
})

export const requestsState = (state: RootState) => state.requests
export const {
    clearRequests,
    setInitialRequests,
    setRequests,
    deleteRequest,
} = requestsSlice.actions
export default requestsSlice.reducer