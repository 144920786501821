import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialBanUserLoadingState } from '../../assets/data/initialStates'
import { BanUserPayload } from '../../types/stateTypes'

export const banUserLoading = createSlice({
    name: 'banUserLoading',
    initialState: initialBanUserLoadingState,
    reducers: {
        openLoading: (state, action:PayloadAction<BanUserPayload>) => {
            state.isLoading = true
            state.actionType = action.payload.actionType
        },
        closeLoading: (state) => {
            state.isLoading = false
        }
    }
})

export const loadingState = (state: RootState) => state.loading
export const { openLoading, closeLoading } = banUserLoading.actions
export default banUserLoading.reducer