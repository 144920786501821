import React from "react";
import { LabelValue } from "../types/stateTypes";

type Props = {
  labelValue: LabelValue;
};

const UserDetailsLabelValue = ({ labelValue }: Props) => {
  return (
    <div className="inline-flex gap-2">
      <div className="w-[100px]">
        <p className="font-bold">{labelValue.label}</p>
      </div>
      <p>{labelValue.value}</p>
    </div>
  );
};

export default UserDetailsLabelValue;
