
import { RFC } from '../../types/reactTypes'
import { TextInputProp } from '../../types/propTypes'
import { ErrorIcon, PasswordInvisible, PasswordVisible } from '../../assets/icons/icons'
import { AdminLogin } from '../../types/serviceTypes'

const SuggesterfyTextInput:RFC<TextInputProp<AdminLogin>> = ({ 
    label,
    name, 
    type,
    value,
    placeholder,
    required,
    isVisible,
    touched,
    error,
    onIconPress,
    onChange,
}) => {
    return (
        <>
            <div className='relative w-full mt-4'>
                <input 
                    name={name}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    required={required ?? true}
                    onChange={onChange}
                    className='w-full px-5 py-4 pr-10 bg-SuggesterfyGrey rounded-[8px] outline-none text-[1.2em] 
                    peer focus:border focus:border-solid focus:shadow-focused'
                />
                <span
                    className='absolute left-0 px-5 py-4 font-poppins text-SuggesterfyDark text-[1.2em]
                    transition-all duration-[0.4s] pointer-events-none ease-in-out
                    peer-focus:text-SuggesterfyWhite peer-focus:translate-x-[12px] peer-focus:translate-y-[-10px] 
                    peer-focus:text-[0.9em] peer-focus:py-0 peer-focus:px-[10px] peer-focus:bg-SuggesterfyPink
                    peer-focus:tracking-wider peer-focus:rounded-[3px]
                    peer-valid:text-SuggesterfyWhite peer-valid:translate-x-[12px] peer-valid:translate-y-[-10px] 
                    peer-valid:text-[0.9em] peer-valid:py-0 peer-valid:px-[10px] peer-valid:bg-SuggesterfyPink
                    peer-valid:tracking-wider peer-valid:rounded-[3px]'
                >
                    {label}
                </span>
                {onIconPress && (
                    isVisible
                    ?
                    <div 
                        className='absolute right-4 top-[1.2em] w-6 h-6 cursor-pointer'
                        onClick={() => onIconPress(false)}
                    >
                        <PasswordVisible/>
                    </div>
                    : 
                    <div 
                        className='absolute right-4 top-[1.2em] w-6 h-6 cursor-pointer'
                        onClick={() => onIconPress(true)}
                    >
                        <PasswordInvisible/>
                    </div>
                )}
            </div>
            {touched && error && (
                <div 
                    className="flex items-center p-3 mt-1 rounded-lg
                    bg-SuggesterfyRed border-t-4 border-SuggesterfyPink dark:bg-SuggesterfyLightPink" 
                    role="alert"
                >
                    <ErrorIcon />
                    <div className="ml-3 text-sm font-medium text-SuggesterfyRed">
                    {error}
                    </div>
                </div>
            )}
        </>
    )
}

export default SuggesterfyTextInput