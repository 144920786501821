import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { Dialog } from '@headlessui/react'
import { closeModal, modalState } from '../../../features/modal/modalSlice'
import { SectionUserIcon } from '../../../assets/icons/icons'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'

const BanUserModal = () => {
  const banState = useSuggesterfySelector(modalState)
  const dispatch = useSuggesterfyDispatch()

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SectionUserIcon />
          <p className='text-xl ml-2'>
            {banState.banType === 'unban' && 'Successfully Banned User'}
            {banState.banType === 'ban' && 'Successfully Unbanned User'}
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description>
        <span className='my-4 w-[36rem] text-center text-lg'>
          You have successfully {banState.banType === 'ban' ? 'banned' : 'unbanned'} user.
        </span>
      </Dialog.Description>
    </>
  )
}

export default BanUserModal