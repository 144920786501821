import { api } from '../app/api';
import {
	PaginationWithUserId,
	PaginationWithSearch,
	ReturnReport,
	ReturnSupport,
	UserId,
	ContactUser,
	ReturnBanUser,
	BannedUser,
	DeletePost,
	DeletePostPayload,
	ExportUserData,
	ExportUserArgs,
} from '../types/serviceTypes';
import { Message, Post, User } from '../types/stateTypes';

export const userApi = api.injectEndpoints({
	endpoints: builder => ({
		getAllUsers: builder.query<User[], PaginationWithSearch>({
			query: ({ take, skip, search }) => ({
				url: `/admin/search-users`,
				params: { take, skip, search },
			}),
			providesTags: ['User'],
		}),
		getBannedUsers: builder.query<BannedUser[], PaginationWithSearch>({
			query: ({ take, skip, search }) => ({
				url: `/admin/search-banned-users`,
				params: { take, skip, search },
			}),
			providesTags: ['User'],
		}),
		getUserCount: builder.query<number, void>({
			query: () => ({
				url: `/admin/user-count`,
			}),
			providesTags: ['User'],
		}),
		getUserDetails: builder.query<User, UserId>({
			query: ({ userId }) => ({
				url: `/admin/users/${userId}/details`,
				params: { userId },
			}),
			providesTags: ['User'],
		}),
		getMessages: builder.query<Message[], PaginationWithUserId>({
			query: ({ take, skip, userId }) => ({
				url: `/admin/${userId}/messages`,
				params: { take, skip },
			}),
			providesTags: ['Message'],
		}),
		getUserQuestions: builder.query<Post[], PaginationWithUserId>({
			query: ({ take, skip, userId }) => ({
				url: `/admin/${userId}/posts`,
				params: { take, skip, type: 'Question' },
			}),
			providesTags: ['Post'],
		}),
		getUserSuggestions: builder.query<Post[], PaginationWithUserId>({
			query: ({ take, skip, userId }) => ({
				url: `/admin/${userId}/posts`,
				params: { take, skip, type: 'Suggestion' },
			}),
			providesTags: ['Post'],
		}),
		getUserRequests: builder.query<ReturnSupport[], PaginationWithUserId>({
			query: ({ take, skip, userId }) => ({
				url: `/admin/${userId}/support-questions`,
				params: { take, skip },
			}),
			providesTags: ['Support'],
		}),
		getUserReports: builder.query<ReturnReport[], PaginationWithUserId>({
			query: ({ take, skip, userId }) => ({
				url: `/admin/${userId}/reported-posts`,
				params: { take, skip },
			}),
			providesTags: ['Report'],
		}),
		deleteUserPost: builder.mutation<DeletePost, DeletePostPayload>({
			query: ({ userId, postId }) => ({
				url: `/users/${userId}/posts/${postId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Post'],
		}),
		banUser: builder.mutation<ReturnBanUser, UserId>({
			query: ({ userId }) => ({
				url: `/admin/ban/${userId}`,
				method: 'POST',
			}),
			invalidatesTags: ['User'],
		}),
		unbanUser: builder.mutation<ReturnBanUser, UserId>({
			query: ({ userId }) => ({
				url: `/admin/unban/${userId}`,
				method: 'POST',
			}),
			invalidatesTags: ['User'],
		}),
		exportUserData: builder.query<ExportUserData, ExportUserArgs>({
			query: ({ startDate, endDate }) => ({
				url: `admin/users/csv/data?startDate=${startDate}&endDate=${endDate}`,
				method: 'GET',
			}),
		}),
		contactUser: builder.mutation<Message, ContactUser>({
			query: ({ message, userId }) => ({
				url: `/admin/contact-user/${userId}`,
				method: 'POST',
				body: { message },
			}),
			invalidatesTags: ['Message'],
		}),
	}),
});

export const {
	useLazyGetAllUsersQuery,
	useLazyGetBannedUsersQuery,
	useGetMessagesQuery,
	useLazyGetUserQuestionsQuery,
	useLazyGetUserSuggestionsQuery,
	useGetUserRequestsQuery,
	useGetUserReportsQuery,
	useDeleteUserPostMutation,
	useBanUserMutation,
	useUnbanUserMutation,
	useContactUserMutation,
	useGetUserDetailsQuery,
	useGetUserCountQuery,
	useLazyExportUserDataQuery,
} = userApi;
