const UserMessageSkeleton = () => {
    return (
        <>
            {Array(4).fill('').map((_,i) => (
            <div
                role='status'
                className='w-full animate-pulse mt-2 py-3 px-7'
                key={i}
            >
                <div>
                    <div className="h-4 bg-gray-200 rounded-full dark:bg-[#CED0D6] w-[300px] mb-2"></div>
                    <div className="w-[500px] h-2 bg-gray-200 rounded-full dark:bg-[#CED0D6] mb-2"></div>
                    <div className="w-[500px] h-2 bg-gray-200 rounded-full dark:bg-[#CED0D6] mb-2"></div>
                </div>
            </div>
            ))}
        </>
    )
}

export default UserMessageSkeleton