import { RFC } from "../../types/reactTypes";
import { DashboardButtonProps } from "../../types/propTypes";

const SuggesterfyDashboardButton: RFC<DashboardButtonProps> = ({
  text,
  btnStyle,
  onClick,
}) => {
  return (
    <button
      type="button"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      className={
        btnStyle === "outlined"
          ? "px-3 py-2 border-2 border-solid outline-none border-SuggesterfyRed text-SuggesterfyRed font-semibold rounded-md hover:bg-SuggesterfyRed hover:text-SuggesterfyWhite transition-all duration-300"
          : "px-3 py-2 h-full bg-SuggesterfyRed text-SuggesterfyWhite rounded-md hover:bg-[#E13724] transition-all duration-300"
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SuggesterfyDashboardButton;
