import { useMemo, useState } from 'react';
import { SEARCH_PILLS } from '../../assets/data/enum';
import SectionTitle from '../molecules/SectionTitle';
import SearchUsers from '../molecules/SearchUsers';
import RenderAllUsers from '../molecules/RenderAllUsers';
import RenderBannedUsers from '../molecules/RenderBannedUsers';
import { debounce } from 'lodash';
import { useGetUserCountQuery } from '../../services/UserService';

const AllUsersList = () => {
	const { data } = useGetUserCountQuery();
	const [searchText, setSearchText] = useState('');
	const [selectedPill, setSelectedPill] = useState(SEARCH_PILLS.ALL);
	const onTextChanged = useMemo(() => debounce(setSearchText, 500), [setSearchText]);

	return (
		<div className="w-full h-[87%] bg-SuggesterfyWhite rounded-[20px] overflow-hidden">
			<SectionTitle page="user_list" title="User List" userCount={data} />
			<SearchUsers
				selectedPill={selectedPill}
				onSelectPill={setSelectedPill}
				onSearchChange={title => onTextChanged(title)}
			/>
			<div className="w-full h-full overflow-y-auto overflow-x-hidden pb-[15em]">
				{selectedPill === SEARCH_PILLS.ALL && <RenderAllUsers searchText={searchText} />}
				{selectedPill === SEARCH_PILLS.BANNED && (
					<RenderBannedUsers searchText={searchText} />
				)}
			</div>
		</div>
	);
};

export default AllUsersList;
