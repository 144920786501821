import React from 'react';

type Props = {
	onClose: () => void;
	onSubmit: () => void;
	onClear: () => void;
};

const CalendarFooter = ({ onClose, onSubmit, onClear }: Props) => {
	return (
		<footer className="col-span-7 justify-between items-center flex mt-3 gap-2 pb-3 border-t pt-3 px-3">
			<div>
				<button className="text-xs text-gray-400" onClick={onClear}>
					Clear
				</button>
			</div>
			<div className="gap-5">
				<button className="text-xs text-SuggesterfyDark mr-3" onClick={onClose}>
					Cancel
				</button>
				<button className="text-xs text-SuggesterfyRed" onClick={onSubmit}>
					Apply
				</button>
			</div>
		</footer>
	);
};

export default CalendarFooter;
