import { Dialog } from "@headlessui/react"
import { useSuggesterfySelector, useSuggesterfyDispatch } from "../../../app/hooks"
import { MODAL_TYPES } from "../../../assets/data/enum"
import { ReportedPostIcon } from "../../../assets/icons/icons"
import { closeModal, modalState, openModal } from "../../../features/modal/modalSlice"
import { selectUserProfile } from "../../../features/user/userSlice"
import SuggesterfyAsyncAvatar from "../../atoms/SuggesterfyAsyncAvatar"
import SuggesterfyDashboardButton from "../../atoms/SuggesterfyDashboardButton"
import PostImageSlider from "../PostImageSlider"
import DOMPurify from 'dompurify'

const UserReportedPostModal = () => {
  const reportState = useSuggesterfySelector(modalState).report
  const dispatch = useSuggesterfyDispatch()
  const hashtags = reportState?.post.hashtags?.map(tag => tag.title)
  const deleteModalState = {
    modalType: MODAL_TYPES.DELETE_REPORT,
    reportId: reportState?.id
  }

  const keepModalState = {
    modalType: MODAL_TYPES.KEEP_REPORT,
    reportId: reportState?.id
  }

  const visitReporterProfile = () => {
    if (!reportState?.user) return
    dispatch(closeModal())
    dispatch(selectUserProfile(reportState.user))
  }

  const content = DOMPurify.sanitize(reportState?.post?.content ?? '')

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <ReportedPostIcon />
          <p className='text-xl ml-2'>
          Reported Post
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <div className='w-full h-[20rem] overflow-x-hidden overflow-y-auto'>
        <p className='text-xl mt-2'>
        Original Post
        </p>
        <div className='flex items-center mt-1'>
          <p className='text-xl font-bold text-SuggesterfyRed'>Q</p>
          <div 
            className='pl-4 font-light tracking-wider'
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        <PostImageSlider images={reportState?.post?.postImages ?? []}/>
        <hr className='suggesterfy-divider'/>
        <p className='text-xl mt-2'>
        Post Reporter
        </p>
        <div className='flex items-center mt-3 rounded-xl bg-SuggesterfyLightGrey px-4 py-3 justify-between'>
          <div className='flex items-center'>
            <SuggesterfyAsyncAvatar imageKey={reportState?.user.avatar} size='medium'/>
            <p className='text-xl font-semibold ml-4'>
              {reportState?.user.firstName}
            </p>
            <p className='text-xl font-semibold ml-2'>
              {reportState?.user.lastName}
            </p>
          </div>
          <SuggesterfyDashboardButton
            text='View Profile'
            btnStyle='filled'
            onClick={visitReporterProfile}
          />
        </div>
        <div className='flex my-4 pb-3 text-SuggesterfyRed items-center'>
          <p className='text-xl font-semibold'>
            Reported For
          </p>
          <p className='text-lg ml-4'>
            {reportState?.reportName}
          </p>
        </div>
        <div className='flex mb-3'>
          <div className='mr-4'>
            <SuggesterfyDashboardButton 
              text='Delete Post'
              btnStyle='outlined'
              onClick={() => dispatch(openModal(deleteModalState))}
            />
          </div>
          <div className='mr-4'>
            <SuggesterfyDashboardButton 
              text='Keep Post'
              btnStyle='filled'
              onClick={() => dispatch(openModal(keepModalState))}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default UserReportedPostModal