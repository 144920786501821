import { useSuggesterfySelector } from '../../app/hooks'
import { loadingState } from '../../features/modal/banUserSlice'
import SuggesterfyLoading from './SuggesterfyLoading'

const SuggesterfyBanUserLoading = () => {
    const banuserState = useSuggesterfySelector(loadingState)
    return (
        <div className='absolute inset-0 bg-SuggesterfyDark/80 z-50'>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[8em] flex flex-col justify-between text-center'>
                <SuggesterfyLoading shape='spinner'/>
                <p className='text-SuggesterfyWhite text-xl'>
                    ...{banuserState.actionType === 'ban' ? 'Banning' : 'Unbanning'} User
                </p>
            </div>
        </div>
    )
}

export default SuggesterfyBanUserLoading