import { api } from '../app/api'

export const fileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        downloadImage: builder.mutation<string, string>({
            query: (imageKey) => ({
                url: '/files/download-image',
                method: 'POST',
                body: { imageKey },
                responseHandler: response => response.text()
            })
        })
    })  
})

export const { useDownloadImageMutation } = fileApi