import { useEffect, useState } from 'react'
import { RFC } from '../../types/reactTypes'
import { BanUserProps } from '../../types/propTypes'
import { useSuggesterfyDispatch } from '../../app/hooks'
import SuggesterfyToggleSwitch from '../atoms/SuggesterfyToggleSwitch'
import { MODAL_TYPES } from '../../assets/data/enum'
import { openModal } from '../../features/modal/modalSlice'
import asyncTimeout from '../../utils/asyncTimeout'
import { closeLoading, openLoading } from '../../features/modal/banUserSlice'
import { BanUserPayload } from '../../types/stateTypes'
import { useBanUserMutation, useUnbanUserMutation } from '../../services/UserService'
import { deleteBannedUser } from '../../features/user/bannedUsersSlice'
import { clearUser } from '../../features/user/userSlice' 

const BanUser:RFC<BanUserProps> = ({ id, bannedUser, user }) => {
    const [ isEnabled, setIsEnabled ] = useState(false)
    const dispatch = useSuggesterfyDispatch()
    const [ banUser ] = useBanUserMutation()
    const [ unbanUser ] = useUnbanUserMutation()
    const loadingState:BanUserPayload = {
        actionType: !isEnabled ? 'ban' : 'unban'
    }
    const userIdType = {
        userId: id
    }
    const unbanUserIdType = {
        userId: user?.id ?? ''
    }

    useEffect(() => {
        if (bannedUser === null) setIsEnabled(false)
        if (bannedUser !== null) setIsEnabled(true)
    },[bannedUser])

    const switchBanUser = async () => {
        try {
            dispatch(openLoading(loadingState))
            const [ data ] =  await Promise.all([
                !isEnabled 
                ? banUser(userIdType).unwrap()
                : unbanUser(unbanUserIdType).unwrap(),
                await asyncTimeout(2000)
            ])
            if (!data) return false
            return true
        } catch (error) {
            return false
        } finally {
            dispatch(closeLoading())
        }
    }
    
    const handleChange = async (isEnabled: boolean) => {
        const result = await switchBanUser()
        if (!result) return
        const modalState = {
            modalType: MODAL_TYPES.BAN_USER,
            userId: id,
            banType: !isEnabled ? 'ban' : 'unban',
        }
        if (isEnabled) {
            dispatch(deleteBannedUser(unbanUserIdType))
            dispatch(clearUser())
        }
        dispatch(openModal(modalState))
        setIsEnabled(!isEnabled)
    }

    return (
        <>
            <div className='flex justify-between items-center px-7'>
                <div className='max-w-[500px]'>
                    <p className='text-lg font-medium'>
                    {!isEnabled ? 'Ban' : 'Unban'} This User
                    </p>
                    <p>
                    {!isEnabled 
                    ? 'By banning this user they will not be able to access their Suggesterfy account, until un-banned.' 
                    : 'By unbanning this user they will be able to access their Suggesterfy account.'
                    }
                    </p>
                </div>
                <div>
                    <SuggesterfyToggleSwitch 
                        enabled={isEnabled}
                        onChange={() => handleChange(isEnabled)}
                    />
                </div>
            </div>
        </>
    )
}

export default BanUser