import { CSVHeader } from '../../types/stateTypes';

export const CSV_HEADERS: CSVHeader[] = [
	{ label: 'Id', key: 'id' },
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Preferred Name', key: 'preferredName' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile', key: 'mobile' },
	{ label: 'Date Of Birth', key: 'dateOfBirth' },
	{ label: 'Account Type', key: 'accountType' },
	{ label: 'Created Date', key: 'createdAt' },
];
