import { Dialog } from "@headlessui/react"
import { useSuggesterfyDispatch, useSuggesterfySelector } from "../../../app/hooks"
import { SupportIcon } from "../../../assets/icons/icons"
import { closeModal, modalState } from "../../../features/modal/modalSlice"
import SuggesterfyDashboardButton from "../../atoms/SuggesterfyDashboardButton"
import SupportReplyCard from "../SupportReplyCard"

const UserSupportQuestionModal = () => {
  const dispatch = useSuggesterfyDispatch()
  const requestState = useSuggesterfySelector(modalState).request

  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SupportIcon />
          <p className='text-xl ml-2'>
            Support Ticket
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <Dialog.Description className='w-full h-full max-h-[30rem] overflow-y-auto break-words'>
        <hr className='suggesterfy-divider'/>
        <div className='w-full'>
          <p className='mt-4 text-2xl font-semibold'>
            {requestState?.title}
          </p>
          <p className='mt-2 text-lg'>
            {requestState?.body}
          </p>
        </div>
        {requestState?.supportReply && requestState?.supportReply.length !== 0 && (
          <>
            <hr className='suggesterfy-divider'/>
            <div className='w-full'>
              {requestState.supportReply.map((reply, index) => (
                <SupportReplyCard 
                  reply={reply} 
                  lastIndex={requestState.supportReply.length - 1 === index}
                />
              ))}
            </div>
          </>
        )}
      </Dialog.Description>
    </>
  )
}

export default UserSupportQuestionModal