import { Dialog } from '@headlessui/react'
import { useSuggesterfyDispatch, useSuggesterfySelector } from '../../../app/hooks'
import { PastMessage } from '../../../assets/icons/icons'
import { closeModal, modalState } from '../../../features/modal/modalSlice'
import SuggesterfyDashboardButton from '../../atoms/SuggesterfyDashboardButton'

const UserMessageModal = () => {
  const dispatch = useSuggesterfyDispatch()
  const messageState = useSuggesterfySelector(modalState).message
  
  return (
    <>
      <Dialog.Title className='flex items-center justify-between'>
        <div className='flex items-center'>
          <PastMessage />
          <p className='text-xl ml-2'>
          Past Message
          </p>
        </div>
        <SuggesterfyDashboardButton 
          text='Close'
          btnStyle='outlined'
          onClick={() => dispatch(closeModal())}
        />
      </Dialog.Title>
      <hr className='suggesterfy-divider'/>
      <Dialog.Description className='w-full break-words'>
        {messageState ? (
          <span className='mt-4'>
            { messageState?.message }
          </span>
        ) : (
        <span className='mt-4 text-SuggesterfyRed'>
          Something went wrong. Please try again later.
        </span>
        )}
      </Dialog.Description>
    </>
  )
}

export default UserMessageModal