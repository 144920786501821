import { createSlice, PayloadAction} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialAdminLoginState } from '../../assets/data/initialStates'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: initialAdminLoginState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email     = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password  = action.payload
        }
    }
})

export const adminState = (state: RootState) => state.admin
export const { 
    setEmail,
    setPassword, 
} = adminSlice.actions
export default adminSlice.reducer