import { RFC } from '../../types/reactTypes'
import { RequestCardProps } from '../../types/propTypes'
import SuggesterfyAsyncAvatar from '../atoms/SuggesterfyAsyncAvatar'

const RequestCard:RFC<RequestCardProps> = ({ 
    request, 
    lastIndex, 
    isSelected,
    onClick, 
}) => {
    const { title, body, user } = request

    return (
        <>
            <div 
                className={isSelected
                    ? 'w-full my-1 px-5 py-4 hover:bg-SuggesterfyLightPink cursor-pointer transition-all duration-200'
                    : 'w-full my-1 px-5 py-4 hover:bg-SuggesterfyLightGrey cursor-pointer transition-all duration-200'
                }
                style={{background: isSelected ? '#FA503D1A' : undefined}}
                onClick={onClick}
            >
                <p className='truncate text-2xl font-semibold'>
                    {title}
                </p>
                <p className='truncate my-2 font-light'>
                    {body}
                </p>
                <div className='flex items-center'>
                    <SuggesterfyAsyncAvatar 
                        imageKey={user?.avatar} 
                        size='small'
                    />
                    <p className='text-lg font-semibold ml-4'>
                        {user?.firstName} {user?.lastName}
                    </p>
                </div>
            </div>
            {!lastIndex && (
            <hr className='suggesterfy-border'/>
            )}
        </>
    )
}

export default RequestCard