import Sidebar from '../../components/templates/Sidebar'
import Main from '../../components/templates/Main'
import SupportRequests from '../../components/templates/Support/SupportRequests'
import SupportTicket from '../../components/templates/Support/SupportTicket'

const Support = () => {
  return (
    <div className='flex overflow-hidden'>
      <Sidebar page='support' />
      <Main>
        <>
          <SupportRequests />
          <SupportTicket />
        </>
      </Main>
    </div>
  )
}

export default Support