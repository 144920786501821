import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { initialReportState } from '../../assets/data/initialStates'
import { ReturnReport } from '../../types/serviceTypes'

export const reportSlice = createSlice({
    name: 'report',
    initialState: initialReportState,
    reducers: {
        selectReport: (state, action:PayloadAction<ReturnReport>) => {
            const { id, reportName, postId, userId, post, user} = action.payload
            state.id         = id
            state.reportName = reportName
            state.postId     = postId
            state.userId     = userId
            state.post       = post
            state.user       = user
        },
        clearReport: (state) => {
            state.id            = ''
            state.reportName    = ''
            state.postId        = ''
            state.userId        = ''
            state.post          = {
                city: '',
                content: '',
                id: '',
                postImages: [],
                type: '',
                userId: '',
                user: {
                    id: '',
                    avatar: '',
                    firstName: '',
                    lastName: '',
                },
            }
            state.user          = {
                id: '',
                avatar: '',
                firstName: '',
                lastName: ''
            }
        }
    }
})

export const postReportState = (state: RootState) => state.report
export const {
    selectReport,
    clearReport,
} = reportSlice.actions
export default reportSlice.reducer