import { RFC } from "../../types/reactTypes";
import { UserOverviewTabProps } from "../../types/propTypes";
import { OVERVIEW_PILLS } from "../../assets/data/enum";
import SuggesterfyPill from "../atoms/SuggesterfyPill";

const UserOverViewTab: RFC<UserOverviewTabProps> = ({
  selectedTab,
  onSelect,
}) => {
  return (
    <>
      <div
        className="w-full relative grid grid-flow-col shadow-slider
            overflow-x-scroll overscroll-x-contain px-7 py-5 gap-3"
      >
        <SuggesterfyPill
          title={OVERVIEW_PILLS.USER_DETAILS}
          isSelected={selectedTab === OVERVIEW_PILLS.USER_DETAILS}
          onSelect={() => onSelect(OVERVIEW_PILLS.USER_DETAILS)}
        />
        <SuggesterfyPill
          title={OVERVIEW_PILLS.MESSAGES}
          isSelected={selectedTab === OVERVIEW_PILLS.MESSAGES}
          onSelect={() => onSelect(OVERVIEW_PILLS.MESSAGES)}
        />
        <SuggesterfyPill
          title={OVERVIEW_PILLS.QUESTIONS}
          isSelected={selectedTab === OVERVIEW_PILLS.QUESTIONS}
          onSelect={() => onSelect(OVERVIEW_PILLS.QUESTIONS)}
        />
        <SuggesterfyPill
          title={OVERVIEW_PILLS.SUGGESTIONS}
          isSelected={selectedTab === OVERVIEW_PILLS.SUGGESTIONS}
          onSelect={() => onSelect(OVERVIEW_PILLS.SUGGESTIONS)}
        />
        <SuggesterfyPill
          title={OVERVIEW_PILLS.SUPPORT_QUESTIONS}
          isSelected={selectedTab === OVERVIEW_PILLS.SUPPORT_QUESTIONS}
          onSelect={() => onSelect(OVERVIEW_PILLS.SUPPORT_QUESTIONS)}
        />
        <SuggesterfyPill
          title={OVERVIEW_PILLS.REPORTED_POSTS}
          isSelected={selectedTab === OVERVIEW_PILLS.REPORTED_POSTS}
          onSelect={() => onSelect(OVERVIEW_PILLS.REPORTED_POSTS)}
        />
      </div>
      <hr className="suggesterfy-border" />
    </>
  );
};

export default UserOverViewTab;
